<div class="row" >
    <link href="https://fonts.googleapis.com/css?family=Buenard:700" rel="stylesheet" type="text/css">


  <!--Video Section-->
  <section class="content-section video-section" style="position: relative;"><span id="controlBar_bgndVideo" class="mb_YTPBar inlinePlayer compact"
     style="white-space: nowrap; position: absolute; z-index: 1000; display: inline-block;"><div class="buttonBar"><span class="mb_YTPPlaypause ytpicon">P</span><span class="mb_YTPMuteUnmute ytpicon">M</span>
        <div class="mb_YTPVolumeBar simpleSlider" style="display: inline-block; cursor: auto;">
            <div class="level horizontal"></div></div>
            <span class="mb_YTPTime">00 : 18 / 01 : 17</span><span class="mb_YTPUrl ytpicon" title="view on YouTube">Y</span>
            <span class="mb_OnlyYT ytpicon">O</span></div><div class="mb_YTPProgress" style="position: absolute;">
                <div class="mb_YTPLoaded" style="position: absolute; left: 0px; width: 100%;"></div><div class="mb_YTPseekbar" style="position: absolute; left: 0px; width: 0px;"></div></div></span>
                <div class="mbYTP_wrapper" id="wrapper_mbYTP_bgndVideo"
                style="position: absolute; z-index: 0; min-width: 100%; min-height: 100%; left: 0px; top: 0px; overflow: hidden; opacity: 1; transition-property: opacity; transition-duration: 2000ms;">

                <!-- <iframe id="mbYTP_bgndVideo" class="playerBox"
                frameborder="0" allowfullscreen="1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" title="YouTube video player"
                style="position: absolute; z-index: 0; width: 2496.92px; height: 1188px; top: 0px; left: 0px; overflow: hidden; opacity: 1; margin-top: -179px; margin-left: -281.96px; transition-property: opacity; transition-duration: 1000ms;"
                allow='autoplay'
                [src]='safeURL'>
              </iframe> -->


                <div class="YTPOverlay" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%;"></div></div>



                <div class="pattern-overlay" style="position: relative;">

                  <!-- <img src="../../assets/images/DCRE_3options_100914-02.jpg" alt="Osse newlogo e537fad9395c466d8217397854660a6476e876e821c87f0b0498ed2f1f3410f3" width="700" height="800"> -->

                  <!-- <a id="bgndVideo" class="player mb_YTPlayer" data-property="{videoURL:'https://www.youtube.com/watch?v=vbciWdq9qfk&amp;list=PLzItZvPaGsOkp-L2ZbE-nKBr0-qFPuyV5&amp;index=6&loop=1&playlist=vbciWdq9qfk',containment:'.video-section', quality:'large',loop:true, autoPlay:true, mute:false, opacity:1}" style="display: none; background-image: none;">bg</a>  -->


                    <div class="row">
                        <div  class="col-lg-1"></div>
                        <div class="col-lg-10">
                          <div>
                            <img src="../../assets/images/DCRE_3options_100914-02.jpg" alt="Osse newlogo e537fad9395c466d8217397854660a6476e876e821c87f0b0498ed2f1f3410f3" width="40%" height="40%" style=" display: block;
                            margin-left: auto;
                            margin-right: auto;">
                          </div>
                          <div>
                            <h2 style="color: black;text-align: center;font-size: 24px; font-weight:bold;">Let's help you get back on track.</h2>
                          </div>
                          <div>
                            <h3 style=" color: black;text-align: center;font-size: 20px;"><b>This website is a one-stop resource for District residents of all ages who want to get back on track with their plans to pursue a secondary education credential and a career pathway.</b></h3>
                          </div>
                          <div>
                            <h3 style=" color: black;text-align: center;font-size: 20px;"><b>The navigation bar above offers the opportunity to learn more about the DC ReEngagement Center (DC REC) and our staff, search for educational and training programs in DC, submit a request to have the DC REC attend an event for outreach, submit a referral for individual services, log-in to a partner portal, and review the DC REC’s current strategic plan.</b></h3>
                          </div>
                          <div>
                            <h3 style=" color: black;text-align: center;font-size: 20px;"><b>Below, an individual seeking services may click on “Find a Program Now” to complete a short questionnaire and connect with staff.</b></h3>
                          </div>
                          <div>
                              <div>
                                <div style="display: block;
                                        margin-left: auto;
                                        margin-right: auto; width:300px;" class="btn btn-info btn-fill" data-toggle="modal" data-target="#matchModal"  onclick="javascript:$('#matchModal').modal('show');"><span class="glyphicon glyphicon-search" aria-hidden="true"></span> Find a Program Now
                                </div>
                              </div>
                         </div>
                         <br>
                        </div>
                        <div class="col-lg-1"></div>
                  </div>

                    </div>
 </section></div>



<!-- Video Modal -->
<div class="modal fade" id="videoModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" onclick="javascript:$('#videoModal').modal('hide');"  aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <br>
      </div>
      <div class="modal-body">
        <div class="embed-responsive embed-responsive-16by9">
          <!-- <iframe id="introvideo" src="https://www.youtube.com/embed/2ih0iH1KIMo" allowscriptaccess frameborder="0" allowfullscreen></iframe> -->
        </div>
      </div>
      <div class="modal-footer">
        <div class="text-center">
          <h5><div class="btn btn-info btn-fill btn-lg" data-toggle="modal" onclick="javascript:$('#matchModal').modal('show');" data-target="#matchModal"><span class="glyphicon glyphicon-search" aria-hidden="true"></span> Find a Program Now</div></h5>

        </div>
      </div>
    </div>
  </div>
</div>
<!-- Match Form -->
<div class="modal fade" id="matchModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal"  onclick="javascript:$('#matchModal').modal('hide');" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <br>
      </div>
      <div class="modal-body">
        <h5>Answer the questions below to find programs in Washington, DC that help students attain a high school credential and much more.</h5>
        <hr>
        <h5>How old are you?</h5>
        <input type="number" class="form-control" [(ngModel)]="Age"  name="q[max_age_gteq]" id="mage" placeholder="Age">
           <input type="hidden" name="q[min_age_lteq]"   id="minage"   />
        <h5>What are your academic goals?</h5>
        <div class="checkbox">
          <label><input type="checkbox"     [(ngModel)]="IsHS_Credentials"   name="q[HS_Credentials]"> High School Diploma, GED, or NEDP</label>
        </div>
         <div class="checkbox">
          <label><input type="checkbox"  [(ngModel)]="Isclass_job_training_true"  name="q[]"> Career Training</label>
        </div>
        <div class="checkbox">
          <label><input type="checkbox" [(ngModel)]="Isclass_english_language_true"  name="q[class_english_language_true]"> Learn English</label>
        </div>
        <h5>What additional services or pregram offerings are you intrested in accessing?</h5>
        <div class="checkbox" >
          <label><input type="checkbox" [(ngModel)]="Isrec_childcare_true"  name="q[rec_childcare_true]" > Childcare Provided</label>
        </div>
        <div class="checkbox">
          <label><input type="checkbox" [(ngModel)]="Isrec_residential_program_true" name="q[rec_residential_program_true]" > Residential Program</label>
        </div>
        <div class="checkbox">
          <label><input type="checkbox" [(ngModel)]="Isrec_night_classes_true" name="q[rec_night_classes_true]" > Night Classes</label>
        </div>
        <div class="checkbox">
          <label><input type="checkbox" [(ngModel)]="Isrec_job_placement_true" name="q[rec_job_placement_true]" > Job Placement</label>
        </div>
        <div class="checkbox">
          <label><input type="checkbox" [(ngModel)]="Isrec_postsec_placement_true"  name="q[rec_postsec_placement_true]" > Post-Secondary Placement</label>
        </div>

      </div>
      <div class="modal-footer">
        <div class="left-side">
          <button type="button" class="btn btn-default btn-simple" onclick="javascript:$('#matchModal').modal('hide');" data-dismiss="modal">Close</button>
        </div>
        <div class="divider"></div>
        <div class="right-side">
          <button onclick="javascript:$('#matchModal').modal('hide');"  (click)="RedirectToFindPrograms();" >Submit</button>
        </div>
      </div>
    </div>
  </div>
</div>

<script>
  function OpenvideoModal()
  {
    $('#videoModal').modal('show');
  }
</script>
