
<!--    
  <% @results.each do |row|  %>
  
  <%  @long =row['address_longitude'] %>
    <%  @lat =row['address_latitude'] %>

  <% end %>



 
<% provide(:title, @program.program_name) %>
<p id="notice"><%= notice %></p> -->

  

<div class="container" style="padding-top:10px;">
  <div class="row">
    <div class="col-md-12">
      <p><span class="glyphicon glyphicon-home" aria-hidden="true"></span> / <a href="/programs">Programs</a> / {{ this.program.program_name }} </p>    
    </div>
  </div>
  
  <div class="row">
    <div class="col-md-8">
      <div class="panel panel-default">
      <div class="panel-body">
        <h3> {{ this.program.program_name }}  </h3>
        <hr> 
        
        <h5 *ngIf="this.program.accepting_applications == '1'"><span class="label label-success"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Enrolling Now</span></h5>
        
        
        <h5 *ngIf="this.program.site_status == 'Not Updated'"><span class="label label-warning"><span class="glyphicon glyphicon-question-sign" aria-hidden="true"></span> Information not verified by program/school</span></h5>
        
        

       <p> Application Deadline: {{ this.program.application_deadline =="0001-01-01T00:00:00" ? '' : this.program.application_deadline  }} </p> 
        <p>Program Hours: {{this.program.program_start_time}} --- {{this.program.program_end_time}}</p>
        <p>Metro: {{ this.program.metro }} </p>
        <p>Bus: {{this.program.bus}}</p>
        <p>{{ this.program.program_description }}</p>
        
        <hr>
        <div class="row">
          <div class="col-md-3">
            <h5>Requirements</h5>
          </div>
          <div class="col-md-9">
            
            <ul>
              <li *ngIf="this.program.req_dc_resident == true">Washington, DC Resident</li> 
              <li *ngIf="this.program.min_age != 0">Minimum Age: {{this.program.min_age}}</li> 
              <li *ngIf="this.program.max_age != 0 && this.program.max_age != 99">Maximum Age: {{this.program.max_age }} </li>
              <li *ngIf="this.program.max_age == 99">Maximum Age: no maximum age limit  </li>
              <li *ngIf="this.program.req_drug_test == true">Drug Testing</li>
              <li *ngIf="this.program.req_vaccination == true">Vaccinations</li>
              <li *ngIf="this.program.math_CASAS_score != 'NA'">Minimum Math CASAS Score: {{this.program.math_CASAS_score}}</li>
              <li *ngIf="this.program.reading_CASAS_score != 'NA'">Minimum Reading CASAS Score: {{this.program.reading_CASAS_score}} </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12"><hr></div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <h5>Credentials and Programs Offered</h5>
          </div>
          <div class="col-md-9">
              <ul>
              <li *ngIf="this.program.credential_ged == '1'">GED (General Educational Development)</li>
              <li *ngIf="this.program.credential_nedp == '1'">NEDP (National External Diploma Program)</li>
              <li *ngIf="this.program.credential_hs_diploma == true">High School Diploma</li>
              <li *ngIf="this.program.credential_servsafe == true"> Serv Safe</li>
              <li *ngIf="this.program.credential_ic3 == true">Digital Literacy Certificate IC3</li>
              <li *ngIf="this.program.class_hs_diploma == true">Adult Basic Education (Math and Reading)</li>
              <li *ngIf="this.program.rec_spanish_instruction == true">Bilingual Instruction - Spanish</li>
              <li *ngIf="this.program.class_english_language == true">English as a Second Language</li>
              <li *ngIf="this.program.class_computer_literacy == true">Computer Literacy</li>
              <li *ngIf="this.program.class_workforce_readiness == true">Workforce Readiness Training</li>
              <li *ngIf="this.program.class_barbering == true">Barbering Classes</li>
              <li *ngIf="this.program.class_cosmetology == true">Cosmetology Classes</li>
              <li *ngIf="this.program.class_computer == true">Computer/Information Technology Classes</li>
              <li *ngIf="this.program.class_hvac == true">Technician Classes (HVAC)</li>
              <li *ngIf="this.program.class_medical == true">Medical Services Classes</li>
              <li *ngIf="this.program.class_culinary == true"> Culinary Arts Classes</li>
              <li *ngIf="this.program.class_construction == true">Construction Training</li>
              <li *ngIf="this.program.rec_automotive_technology == true">Automotive Technology Training</li>
              <li *ngIf="this.program.SupplCred_RO ==  '1'" >Supplemental Credit Recovery Offered</li>
              <li *ngIf="this.program.CollegeReady_AccuP == '1'">College Readiness/Accuplacer Prep</li>
              <li *ngIf="this.program.Family_Literacy == '1'">Family Literacy</li>
              <li *ngIf="this.program.Food_Services == '1'">Food Services</li>
              <li *ngIf="this.program.Childcare == '1'">Childcare</li>
            </ul>
          </div>
        </div>
        
        <div class="row">
          <div class="col-md-12"><hr></div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <h5>Additional Supports and Services</h5>
      </div>
      <div class="col-md-9">
        <ul>
          <li *ngIf="this.program.rec_project_based_learning == true">Project Based Learning</li>
          <li *ngIf="this.program.rec_dual_enrollment == true">Dual College Enrollment</li>
          <li *ngIf="this.program.rec_online_courses == true">Online Courses</li>
          <li *ngIf="this.program.rec_internships == true">Internships/On Job Training</li>
          <li *ngIf="this.program.rec_night_classes == true">Night Classes Offered</li>
          <li *ngIf="this.program.rec_childcare == true">Childcare</li>
          <li *ngIf="this.program.rec_childcare_subsidy == true">Childcare Subsidy</li>
          <li *ngIf="this.program.rec_parenting_support == true">Parenting Support</li>
          <li *ngIf="this.program.rec_independent_living == true">Independent Living</li>
          <li *ngIf="this.program.rec_housing_emergency_shelter == true">Housing/Emergency Shelter</li>
          <li *ngIf="this.program.rec_case_management == true">Case Management</li>
          <li *ngIf="this.program.rec_stipend == true">Student Stipend</li>
          <li *ngIf="this.program.rec_onsite_accomodations == true">On-site Accomodations</li>
          <li *ngIf="this.program.rec_mental_health_services == true">Mental Health Services</li>
          <li *ngIf="this.program.rec_meals == true">Food Provided</li>
          <li *ngIf="this.program.rec_alumni_support == true">Alumni Support</li>
          <li *ngIf="this.program.rec_food_bank == true">Food Bank</li>
          <li *ngIf="this.program.Street_Outreach == '1'">Street Outreach</li>
          <li *ngIf="this.program.Independent_Living == '1'">Independent Living</li>
          <li *ngIf="this.program.Referrals_Provided == '1'">Referrals Provided</li>
          <li *ngIf="this.program.Jobsearch_Placement == '1'">Job Search/Placement</li>
          <li *ngIf="this.program.Postsec_Placement == '1'">Post-Secondary Placement</li>
          <li *ngIf="this.program.Transportation_Subsidy == '1'">Transportation Subsidy</li>
          <li *ngIf="this.program.HealthSA_Counseling == '1'">Health/Substance Abuse Counseling</li>
        </ul>
      </div>
        </div>
        
      </div>
      </div>
  </div>

   <div class="col-md-4"> 
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title"><span class="glyphicon glyphicon-info-sign" aria-hidden="true"></span> Program Information</h3>
      </div>
      <div class="panel-body">
        <agm-map 
        [latitude]="lattitude"
        [longitude]="longitude"
        [zoom]="zoom"
        [disableDefaultUI]="false"
        [mapTypeControl]='true'
        [zoomControl]="false">
                <agm-marker 
                [latitude]="lattitude"
                [longitude]="longitude"
                [markerDraggable]="true"
                >
                
              <agm-info-window>
                <strong>{{this.program.program_name}}</strong>
              </agm-info-window>
              
            </agm-marker>

      </agm-map>
        
            
       
        <p><span class="glyphicon glyphicon-map-marker" aria-hidden="true"></span> {{this.program.address}}</p>
        <p><span class="glyphicon glyphicon-time" aria-hidden="true"></span> {{this.program.building_open_time }}-{{this.program.building_close_time}}</p>
        <p><span class="glyphicon glyphicon-earphone" aria-hidden="true"></span> {{this.program.phone_number }}</p>
        <p><span class="glyphicon glyphicon-globe" aria-hidden="true"></span> <a href="{{this.program.website_url}}" target="_blank"> {{this.program.website_url}} </a></p>
      </div>
    </div>
  </div>
    
  </div>
</div>
 

