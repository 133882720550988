import { Observable, BehaviorSubject } from 'rxjs';
import { extend } from 'lodash';
import { StringUtils } from '../extensions/string';

export abstract class AbstraceStore<T> {
    private _state$: BehaviorSubject<T>;
    state$: Observable<T>;
    private _id: string;
    private _hasStoreChanged: boolean;
    protected constructor(initialState: T, protected _helper: StringUtils) {
        this._state$ = new BehaviorSubject(initialState);
        this.state$ = this._state$.asObservable();
        this._id = '';
        this._hasStoreChanged = false;
    }

    get state(): T {
        return this._state$.getValue();
    }
    get id(): string {
        return this._id;
    }
    get hasStoreChanged(): boolean {
        return this._hasStoreChanged;
    }

    /*
     * this needs to send whole state againto be saved in the store
     */
    public setState = (nextState: T): void => {
        this._hasStoreChanged = false;
        const keys = Object.keys(nextState);

        const prevState = this._state$.getValue();

        if (prevState) {
            for (const k of keys) {
                this._hasStoreChanged = ((nextState as any)[k] !== (prevState as any)[k]);

                if (this._hasStoreChanged) {
                    this._id = this._helper.Guid();
                    nextState = extend({}, nextState, { __storeId: this._id });
                    break;
                }
            }
        }

        this._state$.next(nextState);
    }

    /*
     * this can update only few properties in the store
     */
    patchState = (partialState: any): void => {
        // creating new state from the user properties
        const newState = extend({}, this._state$.getValue(), partialState);
        // saving new state in the store
        this.setState(newState);
    }
}
