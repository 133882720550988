import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IProgramData } from '../findprog/IProgramData';
import { ProgramDataService  } from '../datasources/ProgramsData';
import { DomSanitizer } from '@angular/platform-browser';
import { GoogleMapsModule } from '@angular/google-maps';

@Component({
  selector: 'app-program',
  templateUrl: './program.component.html',
  styleUrls: ['./program.component.css']
})

export class ProgramComponent implements OnInit {
 
  id: any;
  placeUri: string;
  program: IProgramData;
  resultData: IProgramData[]; 
  zoom = 12;
  center: '';
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8
  };
lattitude: number;
longitude: number;
  

mapOptions: google.maps.MapOptions = {
  center: { lat: 0, lng: 0 },
  zoom : 14
}

  constructor(private _Activatedroute: ActivatedRoute,
              private _router: Router,
              private _pdataservice: ProgramDataService,
              private sanitizer: DomSanitizer) {
      console.log('Constructor Id' + this.id);
      //this.program = this._pdataservice.Programsdata[0];
      console.log('Constructor Id' + this.id);
      this.lattitude = 0;

     }
  
  ngOnInit(): void {
    
    this._Activatedroute.paramMap.subscribe(params => { 
      this.id = params.get('id');
      });
    
    this.resultData = this._pdataservice.Programsdata.filter(m => m.Prog_ID === Number(this.id));
    this.program = this.resultData[0];
    this.lattitude = this.program.address_latitude;
    this.longitude = this.program.address_longitude;
    this.mapOptions = {center: { lat: this.lattitude, lng: this.longitude },
    zoom : 14};
  };


}
