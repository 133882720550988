import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import {FindprogComponent} from './findprog/findprog.component';
import { ProgramsComponent } from './programs/programs.component';
import { ProgramComponent } from './program/program.component';
// const routes: Routes = [];


const routes: Routes = [
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'findprog',
    component: FindprogComponent
  },
  {
    path: 'programs',
    component: ProgramsComponent
  },
  {
    path: 'program/:id',
    component: ProgramComponent
  },
   {
    path: '',
    component: HomeComponent
  },
  // {
  //   path:'unauthorized',
  //   component:UnauthorizedComponent 
  // },
  // {
  //   path:'',
  //   redirectTo: '/dashboard', pathMatch: 'full'
  // },
  // {
  //   path:'*',
  //   redirectTo: '/unauthorized', pathMatch: 'full'
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


