import { AbstraceStore } from './abstract.store';
import { Injectable } from '@angular/core';
import { StringUtils } from '../extensions/string';
import { IFilter } from '../../_interface';

@Injectable({
    providedIn: 'root'
})
export class ProgramStore extends AbstraceStore<IFilter> {
    constructor(protected _helper: StringUtils) {
        super({Age: 1}, _helper);
    }
}
