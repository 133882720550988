import { Component, OnInit } from '@angular/core';
import { IProgramData } from './IProgramData';
import { ProgramDataService  } from '../datasources/ProgramsData';
import { Router, ActivatedRoute } from '@angular/router';
import { ProgramStore  } from '../services/store/program.store';
import { Observable } from 'rxjs';
import { IFilter } from '../_interface';
import * as _ from 'lodash';

@Component({
  selector: 'app-findprog',
  templateUrl: './findprog.component.html',
  styleUrls: ['./findprog.component.css']
})
export class FindprogComponent implements OnInit {
  prgmsdata: IProgramData[];
  age: string;
  filter$: Observable<IFilter>;
  resultData: IProgramData[];

  constructor(private _Activatedroute : ActivatedRoute,
    private _pdataservice : ProgramDataService,
    private _programstore: ProgramStore)
    {
    this.filter$ = this._programstore.state$;
    this.resultData = this._pdataservice.Programsdata;
    if (this._programstore.state.IsHS_Credentials){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.HS_Credentials === '1'));
    }
    if (this._programstore.state.Isclass_job_training_true){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_job_training === true));
    }
    if (this._programstore.state.Isclass_english_language_true){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_english_language === true));
    }
    if (this._programstore.state.Isrec_childcare_true){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_childcare === true));
    }
    if (this._programstore.state.Isrec_residential_program_true){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_residential_program === true));
    }
    if (this._programstore.state.Isrec_night_classes_true){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_night_classes === true));
    }
    if (this._programstore.state.Isrec_job_placement_true){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_job_placement === true));
    }
    if (this._programstore.state.Isrec_postsec_placement_true){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_postsec_placement === true));
    }

    if(this._programstore.state.Age != undefined )
    {
        if( Number(this._programstore.state.Age) !=0)
        {
          this.resultData = _.uniq(_.filter(this.resultData, s => s.min_age <= Number(this._programstore.state.Age) ));
          //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.min_age <= Number(this.studentAge) )));
        }
    }

    this.resultData =  _.filter(this.resultData, s => s !== undefined);

    this.prgmsdata = this.resultData.slice(0, 3);
  }

  ngOnInit(): void {
    this._Activatedroute.paramMap.subscribe(params => {
      //this.age = params.get('age')?.toString();
     });
  }

}
