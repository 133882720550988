import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StringUtils {
    constructor() { }

    format(str: string, args: string[]): string {
        if (str) {
            // tslint:disable-next-line: forin
            for (const i in args) {
                str = str.replace(`{${i}}`, args[i]);
            }

            return str;
        }

        return "";
    }

    Guid(): string {
        const s4 = () => {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        };
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
}
