<script src="https://use.fontawesome.com/32f94249f0.js"></script>

<footer class="l-footer">
   <div class="stars">
     <i class="fa fa-star"></i>
     <i class="fa fa-star"></i>
     <i class="fa fa-star"></i>
   </div>
   <div class="l-footer-inner">
       <div class="l-region l-region--footer">
   <nav id="block-menu-menu-community" class="block block--menu block--menu-menu-community">
       <h2 class="block__title">Community</h2>
   
     <ul class="menu">
   <li class="first leaf"><a href="http://calendar.dc.gov/" title="">Citywide Calendar</a></li>
 <li class="leaf"><a href="http://dc.gov/service/find-dc-government-job " title="">DC Jobs</a></li>
 <li class="leaf"><a href="http://mayor.dc.gov/page/contract-and-procurement " title="">DC Procurement</a></li>
 <li class="leaf"><a href="http://dc.gov/service/apply-dc-one-card " title="">DC One Card </a></li>
 <li class="last leaf"><a href="http://green.dc.gov/" title="">Green DC</a></li>
     </ul>
    </nav>

   <nav id="block-menu-menu-dc-government" class="block block--menu block--menu-menu-dc-government">
       <h2 class="block__title">DC Government</h2>
   
 <ul class="menu"><li class="first leaf"><a href="http://dc.gov/page/terms-and-conditions-use" title="">Terms and Conditions</a></li>
 <li class="leaf"><a href="http://dc.gov/page/privacy-and-security" title="">Privacy and Security</a></li>
 <li class="leaf"><a href="http://dc.gov/page/dcgov-accessibility-policy" title="">Accessibility Policy</a></li>
 <li class="leaf"><a href="http://dc.gov/page/laws-regulations-and-courts" title="">DC Laws</a></li>
 <li class="last leaf"><a href="http://mayor.dc.gov/page/cabinet " title="">District Appointees</a></li>
</ul></nav>
<nav id="block-menu-menu-district-news" class="block block--menu block--menu-menu-district-news">
       <h2 class="block__title">District News</h2>
   
 <ul class="menu"><li class="first leaf"><a href="http://dc.gov/newsroom" title="">Statements &amp; Releases</a></li>
 <li class="leaf"><a href="http://dc.gov/page/district-resources-and-social-media" title="">DC.Gov Social Networks</a></li>
 <li class="leaf"><a href="http://mayor.dc.gov/page/chats" title="">Online Chats</a></li>
 <li class="leaf"><a href="http://app.dc.gov/closures/index.asp" title="">Govt Closures</a></li>
 <li class="last leaf"><a href="https://textalert.ema.dc.gov/index.php?CCheck=1" title="">Subscribe to Text Alerts</a></li>
</ul></nav>
<nav id="block-menu-menu-info-centers" class="block block--menu block--menu-menu-info-centers">
       <h2 class="block__title">info centers</h2>
   
 <ul class="menu"><li class="first leaf"><a href="http://mayor.dc.gov/node/545242" title="">Business</a></li>
 <li class="leaf"><a href="http://mayor.dc.gov/page/special-education" title="">Education</a></li>
 <li class="leaf"><a href="http://mayor.dc.gov/page/health-and-wellness" title="">Health</a></li>
 <li class="leaf"><a href="http://dhs.dc.gov/services" title="">Social Services</a></li>
 <li class="last leaf"><a href="http://dhs.dc.gov/service/resources-residents" title="">Residents</a></li>
</ul></nav>
<div id="block-block-2" class="block block--block block--block-2">
       <div class="block__content">
    <h2>Connect with US</h2>

       <div class="contact">
         <h3>DC ReEngagement Center</h3>

         <p>100 42nd Street, NE<br>
           Washington, DC 20019<br>
           Phone: (202) 671-2539<br>
           Email: backontrack@dc.gov</p>
       </div>
      
       <ul class="social">
         <li>
           <a class="social social-facebook" title="Facebook" href="https://www.facebook.com/DCReEngagement" target="_blank" >
             <i class="fa-brands fa-facebook-f" style="width:0%;"></i>
           </a>
         </li>
         <li>
           <a class="social social-twitter" title="Twitter" href="https://twitter.com/DCReEngagement" target="_blank">
             <i class="fa-brands fa-twitter" style="width:0%;"></i>
           </a>
         </li>
         <li>
           <a class="social social-instagram" title="Instagram" href="https://www.instagram.com/dcreengagement/" target="_blank">
             <i class="fa-brands fa-instagram" style="width:0%;"></i>
              
           </a>
         </li>
       </ul>
     </div>  </div>
</div>
 </div>
     <p>
       <strong>{{year}} DC Office of the State
         Superintendent of Education (OSSE).</strong>
     </p>
</footer>

