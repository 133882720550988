import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ProgramStore  } from '../services/store/program.store';
import { IFilter } from '../_interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
filter$: Observable<IFilter>;
Age: number;
IsHS_Credentials: boolean;
Isclass_job_training_true: boolean;
Isclass_english_language_true: boolean;

Isrec_childcare_true: boolean;
Isrec_residential_program_true: boolean;
Isrec_night_classes_true: boolean;
Isrec_job_placement_true: boolean;
Isrec_postsec_placement_true: boolean;
safeURL: SafeResourceUrl;
videoURL: string;
  constructor(private _router: Router,
             private _programstore: ProgramStore,
             private _sanitizer: DomSanitizer) {
                this.filter$ = this._programstore.state$;
                this.Age = 0;
                this.IsHS_Credentials = false;
                this.Isclass_job_training_true = false;
                this.Isclass_english_language_true = false;
                this.Isrec_childcare_true = false;
                this.Isrec_residential_program_true = false;
                this.Isrec_night_classes_true = false;
                this.Isrec_job_placement_true = false;
                this.Isrec_postsec_placement_true = false;
                //this.videoURL="https://www.youtube.com/embed/vbciWdq9qfk?autoplay=1&amp;modestbranding=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;version=3&amp;playerapiid=mbYTP_bgndVideo&amp;origin=https%3A%2F%2Fbackontrackdc.osse.dc.gov&amp;allowfullscreen=true&amp;wmode=transparent&amp;iv_load_policy=3&amp;html5=1&amp;widgetid=1"
                this.videoURL="https://www.youtube.com/embed/vbciWdq9qfk?autoplay=1&amp;modestbranding=1&amp;controls=0&amp;showinfo=0&amp;rel=0&amp;enablejsapi=1&amp;version=3&amp;playerapiid=mbYTP_bgndVideo&amp&loop=1&playlist=vbciWdq9qfk;"
                this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(this.videoURL);
              }

  ngOnInit(): void {

  }

 RedirectToFindPrograms(): void{
  
    this._programstore.patchState({
            Age: this.Age,
            IsHS_Credentials : this.IsHS_Credentials,
            Isclass_job_training_true : this.Isclass_job_training_true,
            Isclass_english_language_true : this.Isclass_english_language_true,
            Isrec_childcare_true : this.Isrec_childcare_true,
            Isrec_residential_program_true : this.Isrec_residential_program_true,
            Isrec_night_classes_true : this.Isrec_night_classes_true,
            Isrec_job_placement_true : this.Isrec_job_placement_true,
            Isrec_postsec_placement_true : this.Isrec_postsec_placement_true
        });
        this._router.navigateByUrl('/findprog');
       // window.location.href='/findprog';
 }

}
