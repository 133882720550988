

  <div id="navbar-full">
    <div id="navbar">
      <nav class="navbar navbar-default" role="navigation">
        <div class="col-md-12">
          <div class="navbar-header">
            <div style="float:left;">
                <a href="./"> <img src="../../assets/images/osse_newlogo-e537fad9395c466d8217397854660a6476e876e821c87f0b0498ed2f1f3410f3.png" alt="Osse newlogo e537fad9395c466d8217397854660a6476e876e821c87f0b0498ed2f1f3410f3" width="120" height="100">
                <span style="color: white; font-size: 24px;font-weight: bold;">Back On Track DC</span>
                </a>
            </div>
            <div style="color: white; font-size: 18px; text-align: right; float: right; padding-top: 30px;">
                  The DC ReEngagement Center is now open to the public for in-person visits!
                  <span>
                    Click <a href="https://osse.dc.gov/service/dc-reengagement-center" target="_blank">
                     <u>here</u>
                    </a> for more information
                  </span>
            </div>
          </div>
        </div>
        <br>
        <div class="col-md-12" style="color: white; font-size:18px; text-align: right; padding-top: 10px;">
          <div style="display:inline;padding:5px 10px 5px 5px;"><a class="anchormenu" href="./">Home</a></div>
          <div style="display:inline;padding:0px"><a class="anchormenu">|</a></div>
          <div style="display:inline;padding:5px 10px 5px 10px;"><a class="anchormenu" href="/about">About</a></div>
          <div style="display:inline;padding:0px"><a class="anchormenu">|</a></div>
          <div style="display:inline;padding:5px 10px 5px 10px;"><a class="anchormenu" href="/programs">Search for Programs</a></div>
          <div style="display:inline;padding:0px"><a class="anchormenu">|</a></div>
          <div style="display:inline;padding:5px 10px 5px 10px;"><a class="anchormenu" href="https://forms.office.com/g/Ce5fy0sDDu" target="_blank">Outreach Request</a></div>
          <div style="display:inline;padding:0px"><a class="anchormenu">|</a></div>
          <div style="display:inline;padding:5px 10px 5px 10px;"><a class="anchormenu" href="https://octo.quickbase.com/db/bqkpv3rsu?a=nwr&nexturl=%2Fdb%2Fbje6txsc6%3Fa%3Dshowpage%26pageid%3D30" target="_blank">Submit Referral</a></div>
          <div style="display:inline;padding:0px"><a class="anchormenu">|</a></div>
          <div style="display:inline;padding:5px 10px 5px 10px;"><a class="anchormenu" href="https://octo.quickbase.com/db/bje6txsc6?a=showpage&edit=1&pageid=28" target="_blank">Educational Partner Portal</a></div>
          <div style="display:inline;padding:0px"><a class="anchormenu">|</a></div>
          <div style="display:inline;padding:5px 10px 5px 10px;"><a class="anchormenu" href="https://octo.quickbase.com/db/bje6txsc6?a=showpage&edit=1&pageid=39" target="_blank">DCPS REC Connect</a></div>
          <div style="display:inline;padding:0px"><a class="anchormenu">|</a></div>
          <div style="display:inline;padding:5px 0px 5px 10px;"><a class="anchormenu" href="https://osse.dc.gov/publication/2022-2026-districtwide-strategic-plan-decrease-youth-disengagement-education" target="_blank">Our Strategic Plan</a></div>
        </div>
      </nav>
    </div>
  </div>
