import { Component, OnInit } from '@angular/core';
import { IProgramData } from '../findprog/IProgramData';
import { ProgramDataService  } from '../datasources/ProgramsData';

import * as _ from 'lodash';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.css']
})
export class ProgramsComponent implements OnInit {

  programCount: number;
   chkHSD: boolean;
   chkGED: boolean;
   chkNEDP: boolean;
   chkEngAsSecondLanguage: boolean;
   checkWorkforceReadiness: boolean;
   chkCareerTraining: boolean;
   chkChildCareProvided: boolean;
   chkBilingulaInstruction: boolean;
   chkResidentialProgram: boolean;
   chkNightClasses: boolean;
   chkOnlineCourses: boolean;
   chkMealsProvided: boolean;
   chkStudentProvided: boolean;
   chkJobPlacement: boolean;
   chkPostSecondaryPlacement: boolean;
   Barbering: boolean;
   chkChildCareCDA: boolean;
   chkMedicalProf: boolean;
   chkCulinaryArts: boolean;
   chkConstruction: boolean;
   chkComputersIT: boolean;
   chkHVACTech: boolean;
   chkAutoTech: boolean;
   chkBarbering: boolean;
   chkCosmetology: boolean;

   programsdata: IProgramData[];
   filter: IProgramData;
   resultData: IProgramData[];
   tempData: IProgramData[];
   searchName: string;
   studentAge: string;
   isCollapsed: boolean;

  constructor(private _pdataservice: ProgramDataService) {

    this.chkHSD = false;
    this.chkGED = false;
    this.chkNEDP = false;
    this.chkEngAsSecondLanguage = false;
    this.checkWorkforceReadiness = false;
    this.chkCareerTraining = false;
    this.chkChildCareProvided = false;
    this.chkBilingulaInstruction = false;
    this.chkResidentialProgram = false;
    this.chkNightClasses = false;
    this.chkOnlineCourses = false;
    this.chkMealsProvided = false;
    this.chkStudentProvided = false;
    this.chkJobPlacement = false;
    this.chkPostSecondaryPlacement = false;
    this.Barbering = false;
    this.chkChildCareCDA = false;
    this.chkMedicalProf = false;
    this.chkCulinaryArts = false;
    this.chkConstruction = false;
    this.chkComputersIT = false;
    this.chkHVACTech = false;
    this.chkAutoTech = false;
    this.chkBarbering  = false;
    this.chkCosmetology = false;
    this.isCollapsed = true;
    this.programsdata = this._pdataservice.Programsdata;
    this.programCount = this.programsdata.length;
  }

  ngOnInit(): void {}

  filterData(): void {

    this.resultData = this._pdataservice.Programsdata;
    this.tempData = this._pdataservice.Programsdata;
  if (  this.chkHSD ||this.chkGED || this.chkNEDP || this.chkEngAsSecondLanguage || this.checkWorkforceReadiness || this.chkCareerTraining ||  this.chkChildCareProvided ||
  this.chkResidentialProgram || this.chkNightClasses || this.chkOnlineCourses || this.chkMealsProvided || this.chkStudentProvided || this.chkJobPlacement || this.chkPostSecondaryPlacement || this.Barbering
  || this.chkChildCareCDA || this.chkMedicalProf || this.chkCulinaryArts || this.chkHVACTech ||this.chkAutoTech || this.chkCosmetology ||
   this.chkBilingulaInstruction || this.chkConstruction || this.chkComputersIT ||
  (this.searchName !='' && this.searchName != undefined )
  || (this.studentAge != '' && this.studentAge != undefined))
  {
    if (this.chkHSD){
      this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.credential_hs_diploma === true)));
    }
    if (this.chkGED){
      this.resultData = _.uniq( _.filter(this.resultData, s => s.credential_ged === '1'));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.credential_ged === '1')));
    }
    if (this.chkNEDP){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.credential_nedp === '1'));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.credential_nedp === '1')));
    }
    if (this.chkEngAsSecondLanguage){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_english_language === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.class_english_language === true)));
    }
    if (this.checkWorkforceReadiness){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_workforce_readiness === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.class_workforce_readiness === true)));
    }

    if (this.chkCareerTraining){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_job_training === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_career_placement === true)));
    }
    if (this.chkChildCareProvided){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_childcare === true));
    //  this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_childcare === true)));
    }
     if (this.chkBilingulaInstruction){
       this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_spanish_instruction === true));
     }

    if (this.chkResidentialProgram){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_residential_program === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_residential_program === true)));
    }
    if (this.chkNightClasses){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_night_classes === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_night_classes === true)));
    }
    if (this.chkOnlineCourses){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_online_courses === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_online_courses === true)));
    }
    if (this.chkMealsProvided){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_meals === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_meals === true)));
    }

    if (this.chkStudentProvided){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_stipend === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_stipend === true)));
    }
    if (this.chkJobPlacement){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_job_placement === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_job_placement === true)));
    }
    if (this.chkPostSecondaryPlacement){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_postsec_placement === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_postsec_placement === true)));
    }
    if (this.Barbering){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_barbering === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.class_barbering === true)));
    }
    if (this.chkChildCareCDA){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_childcare === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_childcare === true)));
    }
    if (this.chkMedicalProf){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_medical === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.class_medical === true)));
    }

    if (this.chkCulinaryArts){
      this.resultData = _.uniq( _.filter(this.resultData, s => s.class_culinary === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.class_culinary === true)));
    }
    if (this.chkConstruction){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_construction === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.class_construction === true)));
    }
     if (this.chkComputersIT){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_computer === true));
      // this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.class_computer === true)));
     }

    if (this.chkHVACTech){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_hvac === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.class_hvac === true)));
    }
    if (this.chkAutoTech){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.rec_automotive_technology === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.rec_automotive_technology === true)));
    }
    if (this.chkCosmetology){
      this.resultData = _.uniq(_.filter(this.resultData, s => s.class_cosmetology === true));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.class_cosmetology === true)));
    }
    if(this.searchName !='' && this.searchName != undefined)
    {
      this.resultData = _.uniq(_.filter(this.resultData, s => s.program_name.indexOf(this.searchName)>=0));
      //this.resultData = _.uniq(_.concat(this.resultData, _.filter(this.tempData, s => s.program_name.indexOf(this.searchName)>0)));
    }

    if(this.studentAge != undefined)
    {
      if( Number(this.studentAge) !=0)
      {
        this.resultData = _.uniq(_.filter(this.resultData, s => (Number(this.studentAge) >= s.min_age   && Number(this.studentAge) <=s.max_age ) ));
      }
    }


  }
  else
  {
    this.resultData = this._pdataservice.Programsdata;
  }
    this.programsdata = _.orderBy(this.resultData, 'program_name', 'asc');
    this.programCount = this.programsdata.length;
}

clearFilter(): void {

  this.chkHSD = false;
  this.chkGED = false;
  this.chkNEDP = false;
  this.chkEngAsSecondLanguage = false;
  this.checkWorkforceReadiness = false;
  this.chkCareerTraining = false;
  this.chkChildCareProvided = false;
  this.chkBilingulaInstruction = false;
  this.chkResidentialProgram = false;
  this.chkNightClasses = false;
  this.chkOnlineCourses = false;
  this.chkMealsProvided = false;
  this.chkStudentProvided = false;
  this.chkJobPlacement = false;
  this.chkPostSecondaryPlacement = false;
  this.Barbering = false;
  this.chkChildCareCDA = false;
  this.chkMedicalProf = false;
  this.chkCulinaryArts = false;
  this.chkConstruction = false;
  this.chkComputersIT = false;
  this.chkHVACTech = false;
  this.chkAutoTech = false;
  this.chkBarbering  = false;
  this.chkCosmetology = false;
  this.programsdata = this._pdataservice.Programsdata;
  this.programCount = this.programsdata.length;
}


}
