import { NgModule  } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms'; 
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppHeaderComponent } from './app-header/app-header.component';
import { AppFooterComponent } from './app-footer/app-footer.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import { FindprogComponent } from './findprog/findprog.component';
import { ProgramsComponent } from './programs/programs.component';
import { ProgramComponent } from './program/program.component';
import { ProgramStore } from './services/store/program.store';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { GoogleMapsModule } from '@angular/google-maps';
import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    AboutComponent,
    HomeComponent,
    FindprogComponent,
    ProgramsComponent,
    ProgramComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgbModule,
    //  GoogleMapsModule,
     AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBpTc_dX23-YA81gEEceAPRNlf2VLQi6BI'
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
