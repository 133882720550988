<div class="row">
  <div class="col-md-1"></div>
  <div class="col-md-10">
    <br>

    <div class="row row-offcanvas row-offcanvas-left">

      <!-- Sidebar Filters ------------------------------------------------------------>
      <div class="col-md-3 col-sm-6 sidebar-offcanvas" id="sidebar" role="navigation">
        <div class="row">
          <div class="col-md-12">
              <h4>We found <span class="text-success">{{programCount}}</span> Program(s)</h4>
          </div>
        </div>
        <div class="panel panel-default">
        <div class="panel-body">
            <div class="form-group">
             <button class="btn btn-default col-md-12" (click)="filterData()">Apply Filters</button>
            <div class="col-md-12"><hr></div>
            <input type="text" class='form-control' [(ngModel)]="searchName" placeholder='Search Programs by Name' />
            <br>
            <input type="text" class='form-control' [(ngModel)]="studentAge" placeholder='Student Age' />
            <input  type="hidden"  name="q[min_age_lteq]"   id="minage"   />
            <br>
            <button class="btn btn-default col-md-12" (click)="clearFilter()">Clean Filters</button>
            <br>
            <!-- <button class="navbar-right" style="background-color:transparent;border-width:0px;padding-top: 6px;"  (click)="clearFilter()">Clean Filters</button> -->
            <br>
            <hr>
            <p>Secondary Credentials Offered</p>
            <div class="col-md-12">
                <input type="checkbox" class="ccheckbox" [(ngModel)]="chkHSD"   />

                <label>High School Diploma</label>
            </div>
            <div class="col-md-12">
                <input type="checkbox" class="ccheckbox"  [(ngModel)]="chkGED"  />
                <label>GED</label>
            </div>
            <div class="col-md-12">
                <input type="checkbox"  class="ccheckbox" [(ngModel)]="chkNEDP"  />
                <label>NEDP</label>
            </div>
            <p>Additional Program Offerings</p>
            <div class="col-md-12">
                <input type="checkbox"  class="ccheckbox"  [(ngModel)]="chkEngAsSecondLanguage"  />
                <label>English as a second language</label>
            </div>
            <div class="col-md-12">
                <input type="checkbox" class="ccheckbox"  [(ngModel)]="checkWorkforceReadiness"  />
                <label>Workforce Readiness</label>
            </div>
            <div class="col-md-12">
                <input type="checkbox" class="ccheckbox" [(ngModel)]="chkCareerTraining"  />
                <label>Career Training</label>

            </div>
            <a class="btn btn-sm btn-simple" role="button" (click)="collapse.toggle()" [attr.aria-expanded]="!isCollapsed">
            <span class="glyphicon glyphicon-plus" aria-hidden="true"></span> Specific Career Training Options
            </a>
            <div class="collapse" id="collapseWorkforce" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
              <div class="col-md-12">
                  <input type="checkbox" class="ccheckbox"  [(ngModel)]="chkBarbering" />
                      <label>Barbering</label>

              </div>
              <div class="col-md-12">
                  <input type="checkbox" class="ccheckbox" [(ngModel)]="chkCosmetology" />
                      <label>Cosmetology</label>

              </div>
              <div class="col-md-12">
                  <input type="checkbox" class="ccheckbox" [(ngModel)]="chkChildCareCDA"  />
                      <label>Childcare (CDA)</label>

              </div>
              <div class="col-md-12">
                  <input type="checkbox" class="ccheckbox" [(ngModel)]="chkMedicalProf"   />
                      <label>Medical Professional</label>

              </div>
              <div class="col-md-12">
                  <input type="checkbox" class="ccheckbox" [(ngModel)]="chkCulinaryArts"    />
                      <label>Culinary Arts</label>

              </div>
              <div class="col-md-12">
                  <input type="checkbox" class="ccheckbox" [(ngModel)]="chkConstruction"  />
                      <label>Construction</label>

              </div>
              <div class="col-md-12">
                  <input type="checkbox" class="ccheckbox" [(ngModel)]="chkComputersIT"   />
                      <label>Computers/IT</label>

              </div>
              <div class="col-md-12">
                  <input type="checkbox" class="ccheckbox" [(ngModel)]="chkHVACTech"    />
                      <label>HVAC Technician</label>

              </div>
              <div class="col-md-12">
                  <input type="checkbox" class="ccheckbox" [(ngModel)]="chkAutoTech"     />
                      <label>Automotive Technology</label>

              </div>
            </div>

            <p>Additional Supports and Services</p>
            <div class="col-md-12">
              <input type="checkbox" class="ccheckbox" [(ngModel)]="chkChildCareProvided" />
            <label>Childcare Provided</label>

            </div>
            <div class="col-md-12">
              <input type="checkbox" class="ccheckbox" [(ngModel)]="chkBilingulaInstruction"   />
            <label>Bilingual Instruction</label>

            </div>
            <div class="col-md-12">
              <input type="checkbox" class="ccheckbox" [(ngModel)]="chkResidentialProgram"   />
            <label>Residential Program</label>

            </div>
            <div class="col-md-12">
              <input type="checkbox" class="ccheckbox"  [(ngModel)]="chkNightClasses"   />
            <label>Night Classes</label>

            </div>
            <div class="col-md-12">
              <input type="checkbox"  class="ccheckbox" [(ngModel)]="chkOnlineCourses"    />
            <label>Online Courses</label>

            </div>
            <div class="col-md-12">
              <input type="checkbox"  class="ccheckbox" [(ngModel)]="chkMealsProvided"  />
            <label>Meals Provided</label>

            </div>
            <div class="col-md-12">
              <input type="checkbox"  class="ccheckbox" [(ngModel)]="chkStudentProvided"   />
            <label>Student Stipend</label>

            </div>
            <div class="col-md-12">
              <input type="checkbox" class="ccheckbox" [(ngModel)]="chkJobPlacement"    />
            <label>Job Placement</label>

            </div>
            <div class="col-md-12">
                <input type="checkbox" class="ccheckbox" [(ngModel)]="chkPostSecondaryPlacement"  />

            <label>Post-Secondary Placement</label>

            </div>
            </div>
        </div>
        </div> <!-- End of Panel Body -->
      </div> <!-- End of Sidebar Filters -->

      <!-- Programs Results Table -->
      <div class="col-md-9">
        <div class="col-md-12 col-sm-12">
        <!-- <button type="button" class="btn btn-default btn-fill btn-round visible-xs visible-sm" data-toggle="offcanvas" data-target=".sidbar-nav">Filters</button> -->
        </div>


        <div class="row" *ngIf="programCount==0">
          <div class="col-md-12">
            <div class="panel panel-default">
              <div class="panel-body text-center">
                 <h4>Sorry, no single program offers all selected criteria. Please reduce the number of criteria selected or contact us at (202) 671-2539 or backontrack@dc.gov if you need assistance.</h4>
              </div>
            </div>
          </div>
        </div>


        <div class="row" *ngIf="programCount!=0"></div>
        <div class="col-md-12" *ngFor="let item of programsdata">

            <div class="list-group">

                 <a class="list-group-item " href="/program/{{item.Prog_ID}}" >
                   <div *ngIf="item.site_status != 'Not Updated'">
                        <h4 class="list-group-item-heading"> {{item.program_name }}  </h4>
                        <div class="list-group-item-text">
                          <p>{{item.program_description }}</p>
                          <p><span class="glyphicon glyphicon-map-marker" aria-hidden="true"></span>{{item.address }} <br>
                          <span class="glyphicon glyphicon-earphone" aria-hidden="true"></span> {{item.phone_number }} </p>
                          <h5 *ngIf="item.accepting_applications == '1' && item.site_status != 'Not Updated'"><span class="label label-success"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Enrolling Now</span></h5>
                        </div>
                   </div>
                   <div *ngIf="item.site_status == 'Not Updated'">
                        <h4 class="list-group-item-heading">{{item.program_name }} </h4>
                        <!-- <div *ngIf="item.accepting_applications == '1'">
                          <p><span class="label label-success"><span class="glyphicon glyphicon-ok" aria-hidden="true"></span> Enrolling Now</span></p>
                        </div>  -->
                        <div class="list-group-item-text">
                          <p>{{ item.program_description }}</p>
                          <p><span class="glyphicon glyphicon-map-marker" aria-hidden="true"></span> {{item.address }} <br>
                          <span class="glyphicon glyphicon-earphone" aria-hidden="true"></span> {{item.phone_number}} </p>
                          <h5 *ngIf="item.site_status == 'Not Updated'"><span class="label label-warning"><span class="glyphicon glyphicon-question-sign" aria-hidden="true"></span> Information not verified by program/school</span></h5>
                      </div>
              </div>
              </a>






            </div>

          </div>
        </div>

    </div>

  </div>
  <div class="col-md-1"></div>
</div>
