import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.css']
})
export class AppFooterComponent implements OnInit {
 year : number;
  constructor() { 
    var currentTime = new Date();
    this.year = currentTime.getFullYear();
  }

  ngOnInit(): void {
    
  }

}
