<div class="container" style="padding-top:1px;">
<div class="row">
<h1 class="text-center">Check Out These Programs!</h1>
<p class="text-center">Based on your answers, here are a few programs that might be a good fit.</p>
</div>
</div>

 <div class="container" id="dvprogramsdata">
  
  <div class="row"  >
    <div class="marketing col-lg-4" *ngFor="let item of prgmsdata" style="width: 33.33%;">
      <div class="panel panel-info">
        <div class="panel-heading">
          <h3 class="panel-title">
           {{ item.program_name }}
              <button type="button" class="btn btn-default btn-sm alert alert-info" *ng-if="item.site_status === 'Not Updated'">
                <span class="glyphicon glyphicon-warning-sign"></span> Information not verified by program/school.
              </button>
          </h3>
        </div>
        <div class="panel-body">
           <p> {{item.program_description}}</p>
            <hr>
            <p><span class="glyphicon glyphicon-map-marker" aria-hidden="true"></span> {{ item.address }}
            <span class="glyphicon glyphicon-earphone" aria-hidden="true"></span> {{ item.phone_number }} </p>
            <hr>  
            <div class="text-center">
            <a href="/program/{{item.Prog_ID}}" > <button id="btnviewmoredetails" class="btn btn-default" >View Model Details</button> </a>
        </div>
      </div>
    </div>
    
  </div>
</div>


<div class="container">
  <div class="row">
    <div class="col-md-12">
      <h5 class="text-center">Can't find what you're looking for?</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 text-center">
      <a href="/programs" > <button id="btnExploreAllPrograms" class="btn btn-warning btn-fill" >Explore All Programs</button></a>
         <!-- <%= link_to 'Explore All Programs', programs_path, class: "btn btn-warning btn-fill" %> -->
    </div>
    <br>
  </div>
</div>
<hr>


