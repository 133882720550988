      <ngb-carousel [interval]="10000" style="height: 380px;overflow:hidden;"  >
          <ng-template ngbSlide>
            <img src="../../assets/images/RECstudents.png" alt="Recstudents 519f6963ad9bc3960a3d6dccdc4880e31853226ee13436ee225aab8a5141d593"  >
              <div class="carousel-caption">
                <span style="font-size: 4.5em;">Student Testimonial</span>  <br>
                <a class="btn btn-lg btn-fill btn-info" data-toggle="modal" onclick="javascript:$('#videoModal1').modal('show');"  data-target="#videoModal1">
                  <span class="glyphicon glyphicon-play" aria-hidden="true"></span> Watch Video</a>
              </div>
          </ng-template>
          <ng-template ngbSlide>
            <img src="../../assets/images/CovenantHouse.png" alt="Covenanthouse 819750a4c27f017c3d7cfe50470a5dda8d1686c62f3e64e4d9720c80f89b62bf">
              <div class="carousel-caption">
                <span style="font-size: 4.5em;">Hear from our school partner, Covenant House</span> <br>
                <p><a class="btn btn-lg btn-fill btn-info" data-toggle="modal" onclick="javascript:$('#videoModal2').modal('show');" data-target="#videoModal2"><span class="glyphicon glyphicon-play" aria-hidden="true"></span> Watch Video</a></p>
              </div>
          </ng-template>
          <ng-template ngbSlide>
            <img src="../../assets/images/BallouSTAY.png" alt="Balloustay 392e5bb0185ceee9344f5f3c30d3e2a62b7d79cc61c6d50575737aeab1adca60">
              <div class="carousel-caption">
                <span style="font-size: 4.5em;">Hear from our school partner, Ballou STAY</span> <br>
                <p><a class="btn btn-lg btn-fill btn-info" data-toggle="modal" onclick="javascript:$('#videoModal3').modal('show');" data-target="#videoModal3"><span class="glyphicon glyphicon-play" aria-hidden="true"></span> Watch Video</a></p>
              </div>
          </ng-template>

      </ngb-carousel>




<div class="container-fluid">
  <div class="row">
    <div class="col-md-1"></div>
    <div  class="col-md-10">
      <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
        <li [ngbNavItem]="1">
          <a ngbNavLink>About</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-md-offset-1 col-md-10">
                <h2>About</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-offset-1 col-md-12">
                <p>This website is a one-stop resource for DC residents who want to get back on track with their plans to pursue a secondary
                  education credential and a career pathway. Users can access up-to-date and reliable information about alternative and adult-serving
                  education programs across the DC. Additionally, individuals who are between 16 - 24 years old who need support as they reconnect to
                  educational options and other critical services that support their attainment of a high school diploma can get in touch directly with
                  the DC ReEngagement Center, an initiative run by the Office of the State Superintendent of Education (OSSE). Schools, community-based organizations,
                  faith-based organizations, members of Raise DC's Disconnected Youth Change Network, and other ReEngagement Center partners will update
                  information on a regular basis. If you are an organization interested in participating in this project, please contact:</p>
                <br>
                <p><b> Staff<br></b></p>
                <div>
                  <table>
                    <tr>
                      <td style="width: 70px;">
                        <img src="../../assets/images/Simmons.png" style="width: 70px; height: 60px;">
                      </td>
                      <td style="text-align: left;">
                        Veronica Simmons (Director) &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href = "mailto:Veronica.Simmons@dc.gov">Veronica.Simmons@dc.gov</a> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="tel:+1-202-803-1173">(202) 803-1173</a>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" Style="font-style: italic;">
                        I promise to be unapologetic, unwavering and selfless in my commitment to serving youth in the District, whether engaged,
                        at-risk, disengaged or enroute. I promise to show up and fight for you every day that I am able too. I promise to be a voice for you.
                        Be an advocate. Be an ally and rally as much support as possible to honor the charge of the DC ReEngagement Center and ensure that
                        equity and access is created for you so that you have the tools and resources that you need to succeed in your life.
                      </td>
                    </tr>
                    <tr><td colspan="2">
                      <hr>
                    </td></tr>
                    <tr>
                      <td style="width: 70px;">
                        <img src="../../assets/images/Burks.png" style="width: 70px; height: 60px;">
                      </td>
                      <td style="text-align: left;">
                        Ashlee Burks (Deputy Director) &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href = "mailto:Ashlee.burks1@dc.gov">Ashlee.burks1@dc.gov</a> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="tel:+1-202-679-4786">(202) 679-4786</a>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" Style="font-style: italic;">
                        I promise to complete my work with intentionality; to carry the servant leadership mindset throughout my career at
                        The DC ReEngagement Center. I promise to ensure data adequacy, accuracy, and outcomes, as every number represents a youth
                        who worked within our center. I promise to use my voice and speak up against anything that does not serve our population. Finally,
                        I promise to leverage resources to ensure needs are met. Ultimately, I Promise to always speak and think with intention, keeping you
                        at the forefront.
                      </td>
                    </tr>
                    <!-- <tr><td colspan="2">
                      <hr>
                    </td></tr>
                    <tr>
                      <td style="width: 70px;">
                        <img src="../../assets/images/Diaz.png" style="width: 70px; height: 60px;">
                      </td>
                      <td style="text-align: left;">
                        Bryan Diaz &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href = "mailto:Bryan.Diaz@dc.gov">Bryan.Diaz@dc.gov</a> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="tel:+1-202-957-7419">(202) 957-7419</a>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" Style="font-style: italic;">
                       I promise I will listen to you I promise I will trust you I promise to see you in a positive light and focus on your strengths
                       I promise to help you develop your weaknesses I promise to push you towards being independent I promise to put heart and soul into
                       my work every day I promise to give you all of me at all timesI promise to try make our shared experience one that is meaningful and
                       most importantly impactful.
                      </td>
                    </tr>-->
                    <tr><td colspan="2">
                      <hr>
                    </td></tr>
                    <tr>
                      <td style="width: 70px;">
                        <img src="../../assets/images/Palmer.png" style="width: 70px; height: 60px;">
                      </td>
                      <td style="text-align: left;">
                        Greg Palmer &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href = "mailto:Gregory.Palmer@dc.gov">Gregory.Palmer@dc.gov</a> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="tel:+1-202-341-6810">(202) 341-6810</a>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" Style="font-style: italic;">
                        I promise to do my best at creating connections and access to post sec opportunities/careers and assist with helping DC REC
                        scholars create the life they want for themselves.
                      </td>
                    </tr>
                    <tr><td colspan="2">
                      <hr>
                    </td></tr>
                    <tr>
                      <td style="width: 70px;">
                        <img src="../../assets/images/Dublin.png" style="width: 70px; height: 60px;">
                      </td>
                      <td style="text-align: left;">
                        Lafayette Dublin &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href = "mailto:Lafayette.Dublin@dc.gov">Lafayette.Dublin@dc.gov</a> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="tel:+1-202-322-1720">(202) 322-1724</a>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" Style="font-style: italic;">
                        I promise to be helpful, intentional, trustworthy, committed, dedicated and fair. I promise to be a consistent advocate
                        for our scholars along their educational journeys to ensure all roadblocks are paved and barriers destroyed. Why? Because
                        I said I would!!! Signed The Great Coach Laf!!
                      </td>
                    </tr>
                    <tr><td colspan="2">
                      <hr>
                    </td></tr>
                    <tr>
                      <td style="width: 70px;">
                        <img src="../../assets/images/Shepard.png" style="width: 70px; height: 60px;">
                      </td>
                      <td style="text-align: left;">
                        Ashley Shepard &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href = "mailto:ashley.shepard1@dc.gov">ashley.shepard1@dc.gov</a> &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;<a href="tel:+1-202-679-8241">(202) 679-8241</a>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" Style="font-style: italic;">
                        I promise to be intentional in my work, to show up not only physically but mentally. I promise to provide a safe and
                        trusting space for our scholars to be able to reach their full potential and to have a self-awareness that my decisions
                        can affect our scholars' experiences.
                      </td>
                    </tr>
                    <tr><td colspan="2">
                      <hr>
                    </td></tr>
                  </table>
                </div>
                <p>Telephone: 202-671-2539<br>
                  Email: Backontrack@dc.gov<br>
                  Referral Link: <a href="https://octo.quickbase.com/db/bqkpv3rsu?a=nwr&nexturl=%2Fdb%2Fbje6txsc6%3Fa%3Dshowpage%26pageid%3D30" target="_blank">https://octo.quickbase.com/db/bqkpv3rsu?a=nwr&nexturl=%2Fdb%2Fbje6txsc6%3Fa%3Dshowpage%26pageid%3D30</a><br>
                  Website: <a href="https://backontrackdc.osse.dc.gov/about" target="_blank" >https://backontrackdc.osse.dc.gov/about</a> <br></p>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2">
          <a ngbNavLink>Purpose of the DC ReEngagement Center</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-md-offset-1 col-md-10">
                <h2>Purpose of the DC ReEngagement Center</h2>
              </div>
            </div>
            <div class="row">
              <div class="col-md-offset-1 col-md-10">
                <p>As with many other major US cities, DC faces a crisis of connection for youth and young adults. As of the 2019-20 school year
                  there were 6,893 youth (ages 16-24) in DC who were not enrolled in school or other educational programs and who do not have a
                  high school diploma.</p>
                <p>As the District continues its efforts to ensure that all students within its pre-K-12 system receive a quality education,
                  it is critical that students who have dropped out also have solid on-ramps to reconnect back to education options that will
                  prepare them for successful adulthood. In response to this need, the District established a ReEngagement Center to serve as a
                  “single-door” through which youth (ages 16-24) who have dropped out can reconnect back to educational options and other critical
                  services to support their attainment of a high school diploma or GED. The Office of the State Superintendent of Education is spearheading
                  this effort with strong support from the Office of the Deputy Mayor for Education, the Department of Employment Services, other key partner
                  agencies, Raise DC's Disconnected Youth Change Network, schools, and community-based organizations.</p>
              </div>
            </div>
            <div class="row">
                <div class="col-md-12"><hr></div>
                <div class="row">
                  <div class="col-md-6">
                    <h2>Come Visit Us. <div class="text-muted">We're open Monday - Friday from 9 AM to 5 PM.</div></h2>
                    <p><strong>Address:</strong><br>
                    100 42nd Street, NE<br>
                    Washington, DC 20019</p>
                    <p><strong>Phone Number:</strong><br>
                    (202) 671-2539</p>
                    <p><strong>Email:</strong><br>
                    backontrack@dc.gov</p>
                  </div>
                  <div class="col-md-6 center">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7825.084091527384!2d-76.94956481025918!3d38.892273901838166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b8ea5cb9f307%3A0xc431632f04249890!2s100+42nd+St+NE%2C+Washington%2C+DC+20019!5e0!3m2!1sen!2sus!4v1540238116817" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen></iframe>
                  </div>
                </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="3">
          <a ngbNavLink>What the DC ReEngagement Center Does</a>
          <ng-template ngbNavContent>
              <div class="row">
                <div class="col-md-offset-1 col-md-10">
                  <h2>What the DC ReEngagement Center Does</h2>
                </div>
              </div>
              <div class="row">
                <div class="col-md-offset-1 col-md-10">
                  <p>We offer a range of services for individuals between 16-24 years old including:</p>
                  <ul>
                    <li>Assessment of academic and non-academic needs to develop individualized reengagement plans</li>
                    <li>Assistance identifying best fit educational options, including District of Columbia Public Schools,
                      public charter schools, community-based organizations, and faith-based organizations</li>
                    <li>Support during the re-enrollment process (collecting documents, accompanying youth on program visits, and connecting youth to resources that address circumstances that led to them leaving school)</li>
                    <li>Ongoing support for at least one year after enrollment occurs</li>
                  </ul>
                  <p>Who is Eligible?</p>
                  <ul>
                    <li>Age: 16 - 24 years old</li>
                    <li>Residence: DC Resident or Ward of DC</li>
                    <li>Education: No high school diploma, GED, or NEDP and not currently enrolled in school</li>
                  </ul>
                </div>
              </div>
              <div class="">
          <div class="row">
            <div class="col-md-12"><hr></div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h2>Come Visit Us. <div class="text-muted">We're open Monday - Friday from 9 AM to 5 PM.</div></h2>
              <p><strong>Address:</strong><br>
              100 42nd Street, NE<br>
              Washington, DC 20019</p>
              <p><strong>Phone Number:</strong><br>
              (202) 671-2539</p>
              <p><strong>Email:</strong><br>
              backontrack@dc.gov</p>
            </div>
            <div class="col-md-6 center">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7825.084091527384!2d-76.94956481025918!3d38.892273901838166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b8ea5cb9f307%3A0xc431632f04249890!2s100+42nd+St+NE%2C+Washington%2C+DC+20019!5e0!3m2!1sen!2sus!4v1540238116817" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen=""></iframe>
            </div>
          </div>
          </div>




          </ng-template>
        </li>
        <li [ngbNavItem]="4">
          <a ngbNavLink>How the DC ReEngagement Center Works</a>
          <ng-template ngbNavContent>

              <div class="row">
                <div class="col-md-12">
                  <h2 class="text-center">How the DC ReEngagement Center Works</h2>
                </div>
              </div>

              <div class="row">
                <div class="col-md-4 text-center">
                  <h4>Come In</h4>
                  <img style="height: 100px;" src="../../assets/images/enter.svg" alt="Enter 3c0ec94836639433c9bbcbd5d05d3c48d3756492b833f9cb0d28e63e573ec270">
                  <p>Walk in or call us at (202) 671-2539 to make an appointment. We're open Monday - Friday from 9a.m. - 5p.m..</p>
                </div>
                <div class="col-md-4 text-center">
                  <h4>Meet with Us</h4>
                  <img style="height: 100px;" src="../../assets/images/conversation.svg" alt="Conversation eb1f8adf3b2eda79a47cac0d9207db7575522a49bc378cadc66a692cd81346d5">
                  <p>Meet with one of our ReEngagement Specialists so we can talk about the supports you need to get back on track!</p>
                </div>
                <div class="col-md-4 text-center">
                  <h4>Connect with Programs</h4>
                  <img style="height: 100px;" src="../../assets/images/graduate.svg" alt="Graduate 5f0cc6a58178d1f798d23af04d223bbe9684f93734d41300ff4567b831736373">
                  <p>We work with District of Columbia Public Schools, public charter schools, , community-based organizations, and faith-based organizations to help you identify a best fit educational option.</p>
                </div>
              </div>




              <div class="">
          <div class="row">
            <div class="col-md-12"><hr></div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <h2>Come Visit Us. <div class="text-muted">We're open Monday - Friday from 9 AM to 5 PM.</div></h2>
              <p><strong>Address:</strong><br>
              100 42nd Street, NE<br>
              Washington, DC 20019</p>
              <p><strong>Phone Number:</strong><br>
              (202) 671-2539</p>
              <p><strong>Email:</strong><br>
              backontrack@dc.gov</p>
            </div>
            <div class="col-md-6 center">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7825.084091527384!2d-76.94956481025918!3d38.892273901838166!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89b7b8ea5cb9f307%3A0xc431632f04249890!2s100+42nd+St+NE%2C+Washington%2C+DC+20019!5e0!3m2!1sen!2sus!4v1540238116817" width="100%" height="400" frameborder="0" style="border:0" allowfullscreen=""></iframe>
            </div>
          </div>
          </div>



          </ng-template>
        </li>
      </ul>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
      <div class="row">
        <div class="col-md-12"><hr></div>
      </div>

      <!-- <div class="row">
          <div  class="col-md-12">
            <p class="text-center">This website is a project of the Office of the State Superintendent of Education's DC ReEngagement Center with design and development contributions from the Next Step Public Charter School.</p>
          </div>
      </div> -->
      </div>
      <div class="col-md-1"></div>
  </div>
</div>
<div>

</div>

<!-- Carousel Video 1 -->
    <div class="modal fade" id="videoModal1" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" onclick="javascript:$('#videoModal1').modal('hide');" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <br>
          </div>
          <div class="modal-body">
            <div class="embed-responsive embed-responsive-16by9">
            <iframe src="https://www.youtube.com/embed/mTDV4iYFwds" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>


<!-- Carousel Video 2 -->
    <div class="modal fade" id="videoModal2" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" onclick="javascript:$('#videoModal2').modal('hide');" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <br>
          </div>
          <div class="modal-body">
            <div class="embed-responsive embed-responsive-16by9">
            <iframe src="https://www.youtube.com/embed/h1NiALLigCQ" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>


<!-- Carousel Video 3 -->
    <div class="modal fade" id="videoModal3" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" onclick="javascript:$('#videoModal3').modal('hide');" aria-label="Close"><span aria-hidden="true">&times;</span></button>
            <br>
          </div>
          <div class="modal-body">
            <div class="embed-responsive embed-responsive-16by9">
            <iframe src="https://www.youtube.com/embed/j3GQlf71p5U" frameborder="0" allowfullscreen></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>


    <script type="text/javascript">
      $(document).ready(function () {
        $('.carousel').carousel({
            interval: 20
        });

          //   $("#mage").focusout(function(){
          //   $("#minage").val($(this).val());
          // });

          // $('#mage').keypress(function() {
          // $('#minage').val($(this).val());
          //   });
            $(".player").mb_YTPlayer();
      });

      $("ul li").on("click",function(){
  $(this).addClass("active").siblings().removeClass("active")
})
      </script>
