import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { IProgramData } from '../findprog/IProgramData';

@Injectable({
    providedIn: 'root'
})
export class ProgramDataService  {
Programsdata: IProgramData[];

    constructor() {
        this.Programsdata = [
         {
           "Prog_ID": 1,
           "program_name": "Academy of Hope PCS (Northeast) Day Program",
           "program_description": "Academy of Hope is an adult public charter school in Washington D.C.- offering excellence in adult education since 1985. Our mission is to provide high quality education and services that change lives and improve our communities. At Academy of Hope- students become part of a learning community where everyone is valued- adds value and is both a teacher and a learner. Our curriculum is experiential and focuses on life skills- which means that students can immediately begin applying what they’re learning to their own lives—and reap the benefits.",
           "address": "2315 18th Place Northeast- Washington- District of Columbia 20018",
           "address_longitude": -76.977708,
           "address_latitude": 38.920776,
           "phone_number": "(202) 269-6623",
           "phone_number_alt": "(202) 269-6623",
           "enrollment_contact_name": "Shaun Ross-Turner",
           "enrollment_contact_email": "shaun@aohdc.org",
           "website_url": "http://www.aohdc.org",
           "min_age": 18,
           "max_age": 99,
           "building_open_time": "09:00AM",
           "building_close_time": "08:00PM",
           "program_start_time": "09:00AM",
           "program_end_time": "02:15PM",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "1",
           "credential_hs_diploma": false,
           "credential_servsafe": true,
           "credential_ic3": true,
           "metro": "Rhode Island Ave-Brentwood",
           "bus": "E2",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "",
           "accepting_applications": "1",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2019-01-22T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 5,
           "class_computer_literacy": true,
           "hours_per_week": 20,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 2,
           "program_name": "Academy of Hope PCS (Northeast) Night Program",
           "program_description": "Academy of Hope is an adult public charter school in Washington D.C.- offering excellence in adult education since 1985. Our mission is to provide high quality education and services that change lives and improve our communities. At Academy of Hope- students become part of a learning community where everyone is valued- adds value and is both a teacher and a learner. Our curriculum is experiential and focuses on life skills- which means that students can immediately begin applying what they’re learning to their own lives—and reap the benefits.",
           "address": "2315 18th Place Northeast- Washington- District of Columbia 20018",
           "address_longitude": -76.977708,
           "address_latitude": 38.920776,
           "phone_number": "(202) 269-6623",
           "phone_number_alt": "(202) 269-6623",
           "enrollment_contact_name": "Shaun Ross-Turner",
           "enrollment_contact_email": "shaun@aohdc.org",
           "website_url": "http://www.aohdc.org",
           "min_age": 18,
           "max_age": 99,
           "building_open_time": "09:00AM",
           "building_close_time": "08:00PM",
           "program_start_time": "06:00PM",
           "program_end_time": "09:00PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "1",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Rhode Island Ave-Brentwood",
           "bus": "E2",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "",
           "accepting_applications": "1",
           "created_at": "2016-11-10T00:00:00",
           "updated_at": "2019-01-22T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 5,
           "class_computer_literacy": true,
           "hours_per_week": 20,
           "rec_night_classes": true,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 3,
           "program_name": "Academy of Hope PCS (Southeast) Day Program ",
           "program_description": "Academy of Hope is an adult public charter school in Washington D.C.- offering excellence in adult education since 1985. Our mission is to provide high quality education and services that change lives and improve our communities. At Academy of Hope- students become part of a learning community where everyone is valued- adds value and is both a teacher and a learner. Our curriculum is experiential and focuses on life skills- which means that students can immediately begin applying what they’re learning to their own lives—and reap the benefits.",
           "address": "421 Alabama Avenue Southeast- Washington- District of Columbia 20032",
           "address_longitude": -76.999884,
           "address_latitude": 38.842743,
           "phone_number": "(202) 373-0246",
           "phone_number_alt": "(202) 373-0246 x226",
           "enrollment_contact_name": "Jamala Smith",
           "enrollment_contact_email": "jamala@aohdc.org",
           "website_url": "http://www.aohdc.org",
           "min_age": 18,
           "max_age": 99,
           "building_open_time": "09:00AM",
           "building_close_time": "07:30PM",
           "program_start_time": "09:00AM",
           "program_end_time": "02:15PM",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "1",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": true,
           "metro": "Congress Heights",
           "bus": "A8,A4,A2,W1, W4",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "",
           "accepting_applications": "1",
           "created_at": "2014-11-10T00:00:00",
           "updated_at": "2019-01-22T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 8,
           "class_computer_literacy": true,
           "hours_per_week": 20,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 4,
           "program_name": "Academy of Hope PCS (Southeast) Night Program ",
           "program_description": "Academy of Hope is an adult public charter school in Washington D.C., offering excellence in adult education since 1985. Our mission is to provide high quality education and services that change lives and improve our communities. At Academy of Hope, students become part of a learning community where everyone is valued, adds value and is both a teacher and a learner. Our curriculum is experiential and focuses on life skills, which means that students can immediately begin applying what they’re learning to their own lives—and reap the benefits.",
           "address": "421 Alabama Avenue Southeast, Washington, District of Columbia 20032",
           "address_longitude": -76.999884,
           "address_latitude": 38.842743,
           "phone_number": "(202) 373-0246",
           "phone_number_alt": "(202) 373-0246 x226",
           "enrollment_contact_name": "Jamala Smith",
           "enrollment_contact_email": "jamala@aohdc.org",
           "website_url": "http://www.aohdc.org",
           "min_age": 18,
           "max_age": 99,
           "building_open_time": "09:00AM",
           "building_close_time": "07:30PM",
           "program_start_time": "05:00PM",
           "program_end_time": "08:00PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "1",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Congress Heights",
           "bus": "A8,A4,A2,W1, W4",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "",
           "accepting_applications": "1",
           "created_at": "2016-11-10T00:00:00",
           "updated_at": "2019-01-22T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 8,
           "class_computer_literacy": true,
           "hours_per_week": 20,
           "rec_night_classes": true,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 5,
           "program_name": "Anacostia Community Outreach Center ",
           "program_description": "To provide a comprehensive array of individual, family and community engagement services which create a network of core supports to reduce poverty, strengthen families, improve communities and increase the number of realistic options for individuals to succeed in life.",
           "address": "707 24th Street Northeast B-Level, Washington, District of Columbia 20002",
           "address_longitude": -76.981588,
           "address_latitude": 38.905276,
           "phone_number": "(202) 889-5607",
           "phone_number_alt": "",
           "enrollment_contact_name": "",
           "enrollment_contact_email": "",
           "website_url": "http://www.anacostiaoutreach.org",
           "min_age": 18,
           "max_age": 99,
           "building_open_time": "09:00AM",
           "building_close_time": "04:00PM",
           "program_start_time": "",
           "program_end_time": "",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": false,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Stadium-Armory",
           "bus": "X2, Street Car",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "",
           "accepting_applications": "0",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2017-09-22T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": true,
           "ward": 5,
           "class_computer_literacy": false,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 6,
           "program_name": "Ballou STAY (Comprehensive High School Program)",
           "program_description": "Ballou S.T.A.Y (School To Aid Youth) High School was established in 1989 as an alternative high school to provide an opportunity for DC residents to complete their high school education.  Ballou STAY Opportunity Academy offers traditional diploma programming through Summit Personalized Learning, an instructional program that integrates high-quality, individualized digital learning in all core subjects; offering specialized programming to prepare our students for college and the workplace; empowering our young adults with the career skills to succeed in the real world.  In addition to vocational training such as cosmetology and barbering, Ballou STAY Opportunity Academy offers adult basic education classes within our National External diploma program. Our commitment to our students goes beyond academic achievement, we empower our students transition to life after receiving a certification or high school diploma.",
           "address": "3401 4th Street Southeast, Washington, District of Columbia 20032",
           "address_longitude": -77.001423,
           "address_latitude": 38.839356,
           "phone_number": "(202) 645-3390",
           "phone_number_alt": "(202) 645-3390",
           "enrollment_contact_name": "Cara Fuller",
           "enrollment_contact_email": "cara.fuller@dc.gov",
           "website_url": "http://www.balloustay.com",
           "min_age": 16,
           "max_age": 24,
           "building_open_time": "09:00AM",
           "building_close_time": "07:00PM",
           "program_start_time": "09:15AM",
           "program_end_time": "04:20PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": true,
           "class_cosmetology": true,
           "class_childcare": true,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": true,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "1",
           "credential_hs_diploma": true,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Anacostia",
           "bus": "A6, A7, W4, A2, A8",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 485,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Semi-Annual",
           "accepting_applications": "1",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2018-11-28T00:00:00",
           "site_status": "Updated",
           "rec_childcare": true,
           "ward": 8,
           "class_computer_literacy": false,
           "hours_per_week": 30,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "24"
         },
         {
           "Prog_ID": 7,
           "program_name": "Ballou STAY (NEDP Program)",
           "program_description": "Ballou S.T.A.Y (School To Aid Youth) High School was established in 1989 as an alternative high school to provide an opportunity for DC residents to complete their high school education.  .  In addition to vocational training such as cosmetology and barbering, Ballou STAY Opportunity Academy offers adult basic education (ABE) classes within our National External diploma program. Our commitment to our students goes beyond academic achievement, we empower our students transition to life after receiving a certification or high school diploma.",
           "address": "3401 4th Street Southeast, Washington, District of Columbia 20032",
           "address_longitude": -77.001423,
           "address_latitude": 38.839356,
           "phone_number": "(202) 645-3390",
           "phone_number_alt": "(202) 645-6711",
           "enrollment_contact_name": "Brenda Johnson",
           "enrollment_contact_email": "cara.fuller@dc.gov",
           "website_url": "http://www.balloustay.com",
           "min_age": 22,
           "max_age": 99,
           "building_open_time": "09:15AM",
           "building_close_time": "07:00PM",
           "program_start_time": "10:00AM",
           "program_end_time": "07:00AM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": true,
           "class_cosmetology": true,
           "class_childcare": true,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "1",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Anacostia",
           "bus": "A6, A7, W4, A2, A8",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 100,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2016-06-21T00:00:00",
           "updated_at": "2018-11-28T00:00:00",
           "site_status": "Updated",
           "rec_childcare": true,
           "ward": 8,
           "class_computer_literacy": false,
           "hours_per_week": 30,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "6th",
           "reading_CASAS_score": "6th",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 8,
           "program_name": "Briya Public Charter School",
           "program_description": "Briya’s mission is to strengthen families through culturally responsive two-generation education.",
           "address": "2333 Ontario Road Northwest, Washington, District of Columbia 20009",
           "address_longitude": -77.039802,
           "address_latitude": 38.920963,
           "phone_number": "(202) 232-7777",
           "phone_number_alt": "(202) 797-7337",
           "enrollment_contact_name": "Mirna Guardado",
           "enrollment_contact_email": "Mguardado@briya.org",
           "website_url": "www.briya.org",
           "min_age": 16,
           "max_age": 99,
           "building_open_time": "08:00AM",
           "building_close_time": "05:00PM",
           "program_start_time": "09:00AM",
           "program_end_time": "09:00PM",
           "class_hs_diploma": false,
           "class_job_training": true,
           "class_english_language": true,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": true,
           "class_medical": true,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": true,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "1",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Columbia Heights",
           "bus": "42, H1, H3, H8 ",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 35,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "2021-04-19T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2015-10-06T00:00:00",
           "updated_at": "2021-01-28T00:00:00",
           "site_status": "Updated",
           "rec_childcare": true,
           "ward": 1,
           "class_computer_literacy": true,
           "hours_per_week": 12,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": true,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": true,
           "rec_mental_health_services": true,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "1",
           "Family_Literacy": "1",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 9,
           "program_name": "Capital Guardian Youth Challenge Academy",
           "program_description": "To provide opportunities for personal growth, self-improvement and academic achievement to District of Columbia teens 16 through 18 years of age. We provide opportunities for disengaged high school students and high school dropouts; through a highly structured military styled, non-traditional educational environment; via career training, mentoring, diverse educational and extracurricular activities.",
           "address": "2001 East Capitol Street Southeast, Washington, District of Columbia 20003",
           "address_longitude": -76.975397,
           "address_latitude": 38.888732,
           "phone_number": "(202) 730-1579",
           "phone_number_alt": "(202) 997-3824",
           "enrollment_contact_name": "Gerard Waluyn",
           "enrollment_contact_email": "gerard.waluyn@dc.gov",
           "website_url": "cgyca.org",
           "min_age": 16,
           "max_age": 18,
           "building_open_time": "09:30AM",
           "building_close_time": "06:00PM",
           "program_start_time": "08:30AM",
           "program_end_time": "04:30PM",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": true,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": true,
           "rec_online_courses": false,
           "rec_credit_recovery": true,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": true,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": true,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Stadium-Armory",
           "bus": "B2, D6, E32, 96, 97",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": true,
           "req_notes": "0",
           "open_seats": 105,
           "application_deadline": "2020-03-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "2020-03-29T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2020-12-22T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 7,
           "class_computer_literacy": false,
           "hours_per_week": 35,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": true,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": true,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": true,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": true,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "1",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "1",
           "Family_Literacy": "0",
           "Food_Services": "1",
           "Childcare": "0",
           "Street_Outreach": "1",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "18"
         },
         {
           "Prog_ID": 10,
           "program_name": "Carlos Rosario International PCS (Ward 1)",
           "program_description": "The Carlos Rosario offers tuition-free English, Career Training, GED and Citizenship classes to thousands of adults who live in DC. The school was founded for immigrants and English-learners and is open to the entire DC community. Our students obtain high school diplomas; pass the citizenship exam and become U.S. citizens; gain the English skills necessary to help their children with homework; enter into careers and climb career ladders; and obtain college degrees and workforce certifications.",
           "address": "1100 Harvard Street Northwest, Washington, District of Columbia 20009",
           "address_longitude": -77.02765,
           "address_latitude": 38.926369,
           "phone_number": "(202) 797-4700",
           "phone_number_alt": "(202) 797-4700 x111",
           "enrollment_contact_name": "Karina Ortez",
           "enrollment_contact_email": "kortez@carlosrosario.org",
           "website_url": "http://www.carlosrosario.org/",
           "min_age": 16,
           "max_age": 0,
           "building_open_time": "08:30AM",
           "building_close_time": "09:00PM",
           "program_start_time": "09:00AM",
           "program_end_time": "09:00PM",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": true,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": true,
           "class_culinary": true,
           "class_computer": true,
           "class_hvac": false,
           "class_construction": true,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": true,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": true,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": true,
           "credential_ic3": true,
           "metro": "Columbia Heights",
           "bus": "62, 63, 64, H1, H2, H4, H8",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "2020-01-10T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "2020-01-21T00:00:00",
           "enrollment_cycles": "Semi-Annual",
           "accepting_applications": "1",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2019-12-17T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 1,
           "class_computer_literacy": true,
           "hours_per_week": 15,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": true,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": true,
           "rec_mental_health_services": true,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "1",
           "Family_Literacy": "1",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 11,
           "program_name": "Carlos Rosario International PCS (Ward 5)",
           "program_description": "The Carlos Rosario offers tuition-free English, Career Training, GED and Citizenship classes to thousands of adults who live in DC. The school was founded for immigrants and English-learners and is open to the entire DC community. Our students obtain high school diplomas; pass the citizenship exam and become U.S. citizens; gain the English skills necessary to help their children with homework; enter into careers and climb career ladders; and obtain college degrees and workforce certifications.",
           "address": "514 V Street Northeast, Washington, District of Columbia 20002",
           "address_longitude": -76.998634,
           "address_latitude": 38.918371,
           "phone_number": "(202) 734-4900",
           "phone_number_alt": "(202) 797-4700 x111",
           "enrollment_contact_name": "Karina Ortez",
           "enrollment_contact_email": "kortez@carlosrosario.org",
           "website_url": "http://www.carlosrosario.org/",
           "min_age": 16,
           "max_age": 0,
           "building_open_time": "08:30AM",
           "building_close_time": "09:00PM",
           "program_start_time": "08:45AM",
           "program_end_time": "09:00PM",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": true,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": true,
           "class_culinary": true,
           "class_computer": true,
           "class_hvac": false,
           "class_construction": true,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": true,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": true,
           "credential_ic3": true,
           "metro": "Rhode Island Ave-Brentwood",
           "bus": "M31, P6, D8",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "2020-01-10T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "2020-01-21T00:00:00",
           "enrollment_cycles": "Semi-Annual",
           "accepting_applications": "1",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2019-12-17T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 5,
           "class_computer_literacy": true,
           "hours_per_week": 15,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": true,
           "rec_internships": true,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": true,
           "rec_mental_health_services": true,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": true,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "1",
           "Family_Literacy": "0",
           "Food_Services": "1",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 12,
           "program_name": "Catholic Charities Bilingual Bank Telling Program",
           "program_description": "INVESTING IN BILINGUAL BANKING & FINANCE PROFESSIONALS PROJECT- BANK TELLER PROGRAM The Bank Teller Program prepares bilingual students for an entry level position in the financial industry. The curriculum will address customer service financial principles and workforce preparedness. In addition, students participate in a comprehensive workforce program that focuses on training- placement and retention in addition to highly focused case management. Students will frequently interact with professionals in the banking sector who assist in the instruction of the Bank Telling Program. ",
           "address": "1618 Monroe Street NW, Washington, District of Columbia 20010",
           "address_longitude": -77.0373987,
           "address_latitude": 38.932731,
           "phone_number": "",
           "phone_number_alt": "(202) 939-2400 x965",
           "enrollment_contact_name": "Norma Solano",
           "enrollment_contact_email": "norma.solano@cc-dc.org",
           "website_url": "catholiccharitiesdc.org",
           "min_age": 18,
           "max_age": 0,
           "building_open_time": "08:30AM",
           "building_close_time": "04:30PM",
           "program_start_time": "",
           "program_end_time": "",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Columbia Heights",
           "bus": "",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 20,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2018-08-09T00:00:00",
           "updated_at": "2018-11-07T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 1,
           "class_computer_literacy": false,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "",
           "reading_CASAS_score": "",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 13,
           "program_name": "Catholic Charities Green Construction Program",
           "program_description": "GREEN CONSTRUCTION PROGRAM This is a construction program for students interested in pursuing a career pathway in the construction field. When completed, students will graduate with four certifications: NCCER Core Curriculum- Flagger- OSHA-10 Safety and CPR-AED/First Aid. The course will prepare students with foundational knowledge of construction in hand tools",
           "address": "1618 Monroe Street NW, Washington, District of Columbia 20010",
           "address_longitude": -77.0373987,
           "address_latitude": 38.932731,
           "phone_number": "",
           "phone_number_alt": "(202) 939-2400 x932",
           "enrollment_contact_name": "Celia Sterling",
           "enrollment_contact_email": "celia.sterling@cc-dc.org",
           "website_url": "www.catholiccharitiesdc.org",
           "min_age": 18,
           "max_age": 0,
           "building_open_time": "08:30AM",
           "building_close_time": "04:30PM",
           "program_start_time": "",
           "program_end_time": "",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": true,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Columbia Heights",
           "bus": "",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 20,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Other",
           "accepting_applications": "1",
           "created_at": "2018-07-06T00:00:00",
           "updated_at": "2018-11-07T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 1,
           "class_computer_literacy": false,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "",
           "reading_CASAS_score": "",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 15,
           "program_name": "Catholic Charities Kennedy Employment Services and Adult Education",
           "program_description": "This course is designed to help clients be successful in the workforce. The focus of the course is to expand on the student’s literacy and math skills. In addition, students will be introduced to workforce preparedness and increase their digital literacy skills. The program also  serves as a bridge course for students interested in the field of hospitality, construction, and bank telling. Students will also have an ntroduction to financial literacy. ",
           "address": "Washington , District of Columbia 20017",
           "address_longitude": -77.0373987,
           "address_latitude": 38.932731,
           "phone_number": "(202) 281-2700",
           "phone_number_alt": "",
           "enrollment_contact_name": "Chandra Connolly ",
           "enrollment_contact_email": "chandra.connolly@cc-dc.org ",
           "website_url": "",
           "min_age": 18,
           "max_age": 0,
           "building_open_time": "09:00AM",
           "building_close_time": "05:00PM",
           "program_start_time": "10:00AM",
           "program_end_time": "02:00AM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": false,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Fort Totten",
           "bus": "80",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2018-11-07T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 5,
           "class_computer_literacy": false,
           "hours_per_week": 30,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "0",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 16,
           "program_name": "Community College Prep PCS @ MC Terrell",
           "program_description": "The mission of Community College Preparatory Academy {CC Prep} is to provide the education and skills development that will empower and prepare under-credited adults for postsecondary education success, viable employment and lifelong learning.",
           "address": "3301 Wheeler Road Southeast, Washington, District of Columbia 20032",
           "address_longitude": -76.99399,
           "address_latitude": 38.842272,
           "phone_number": "(202) 770-3252",
           "phone_number_alt": "(202) 770-3252",
           "enrollment_contact_name": "Mrs. Parker",
           "enrollment_contact_email": "denise@ccprep-academy.org",
           "website_url": "http://www.ccprep-academy.org",
           "min_age": 18,
           "max_age": 99,
           "building_open_time": "09:00AM",
           "building_close_time": "09:00PM",
           "program_start_time": "09:00AM",
           "program_end_time": "09:00PM",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": true,
           "class_hvac": true,
           "class_construction": false,
           "rec_online_courses": true,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Congress Heights",
           "bus": "A6, A7, W2, W3",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "0",
           "created_at": "2016-09-28T00:00:00",
           "updated_at": "2018-07-06T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 8,
           "class_computer_literacy": true,
           "hours_per_week": 15,
           "rec_night_classes": true,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 17,
           "program_name": "Congress Heights Community Training and Development Corporation",
           "program_description": "Site Address: 1351 Alabama Ave. SE; Washington DC 20020",
           "address": "3215 Martin Luther King, Jr., Avenue SE, Washington, District of Columbia 20032",
           "address_longitude": -77.001326,
           "address_latitude": 38.843067,
           "phone_number": "(202) 563-5200",
           "phone_number_alt": "(202) 528-0865",
           "enrollment_contact_name": "Troy Shockley",
           "enrollment_contact_email": "troy@chctdc.org",
           "website_url": "http://www.chctdc.org/",
           "min_age": 18,
           "max_age": 0,
           "building_open_time": "12:00PM",
           "building_close_time": "08:00PM",
           "program_start_time": "12:00PM",
           "program_end_time": "08:00PM",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": true,
           "class_hvac": false,
           "class_construction": true,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": true,
           "metro": "Congress Heights",
           "bus": "A4, A8, A6, A7, A2, W4",
           "req_dc_resident": true,
           "req_drug_test": true,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 20,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Semi-Annual",
           "accepting_applications": "1",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2019-01-07T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 8,
           "class_computer_literacy": true,
           "hours_per_week": 24,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": true,
           "rec_internships": false,
           "math_CASAS_score": "4th",
           "reading_CASAS_score": "6th",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": true,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "1",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "1",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 18,
           "program_name": "Covenant House Washington",
           "program_description": "A good education is fundamental to achieving financial independence as well as a successful career. Covenant House Washington offers a flexible GED preparation program that allows young people ages 18-24 to follow their dreams by successfully completing their high school education.  Our curriculum focuses on the mastery of subject matter tested during the GED exam- Science, Social Studies, Mathematics, and Language Arts.  Our experienced and compassionate teachers and volunteers work closely with youth, offering one-on-one tutoring and mentoring to ensure youth’s confidence and educational success.",
           "address": "2001 Mississippi Avenue Southeast, Washington, District of Columbia 20020",
           "address_longitude": -76.974223,
           "address_latitude": 38.843527,
           "phone_number": "(202) 610-9600",
           "phone_number_alt": "(202) 610-9674",
           "enrollment_contact_name": "Madye Henson",
           "enrollment_contact_email": "mhenson@chdc.org",
           "website_url": "http://www.covenanthousedc.org/",
           "min_age": 18,
           "max_age": 24,
           "building_open_time": "08:00AM",
           "building_close_time": "05:00PM",
           "program_start_time": "10:00AM",
           "program_end_time": "03:00PM",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": true,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": true,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": true,
           "credential_servsafe": true,
           "credential_ic3": false,
           "metro": "Southern Avenue",
           "bus": "W2, W3, W1 ",
           "req_dc_resident": false,
           "req_drug_test": true,
           "req_vaccination": true,
           "req_notes": "0",
           "open_seats": 25,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "0",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2018-07-06T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": true,
           "ward": 8,
           "class_computer_literacy": true,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": true,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": true,
           "rec_food_bank": false,
           "rec_residential_program": true,
           "rec_job_placement": true,
           "class_workforce_readiness": true,
           "rec_independent_living": true,
           "rec_housing_emergency_shelter": true,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "1",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "1",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "24"
         },
         {
           "Prog_ID": 19,
           "program_name": "Four Walls Career and Technical Education Center",
           "program_description": "The mission of the Four Walls Career and Technical Education Center is to provide unemployed and underemployed District of Columbia residents with basic literacy skills, employ-ability skills, career and  technical education as a means of increasing self-sufficiency and improving quality of life.",
           "address": "1125 Neal Street Northeast, Washington, District of Columbia 20002",
           "address_longitude": -76.990562,
           "address_latitude": 38.90524,
           "phone_number": "(202) 332-8022",
           "phone_number_alt": "(202) 332-8022",
           "enrollment_contact_name": "Jamise Chavis",
           "enrollment_contact_email": "jchavis@fourwallsctec.org",
           "website_url": "FourWallsCTEC.org",
           "min_age": 18,
           "max_age": 99,
           "building_open_time": "09:30AM",
           "building_close_time": "08:00PM",
           "program_start_time": "10:00AM",
           "program_end_time": "08:00PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": true,
           "class_hvac": false,
           "class_construction": true,
           "rec_online_courses": true,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "1",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "NoMa-Gallaudet U",
           "bus": "90, 92",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 80,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Other",
           "accepting_applications": "1",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2018-11-07T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 5,
           "class_computer_literacy": false,
           "hours_per_week": 24,
           "rec_night_classes": true,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": true,
           "rec_internships": false,
           "math_CASAS_score": "6th",
           "reading_CASAS_score": "6th",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 20,
           "program_name": "Goodwill Excel Center",
           "program_description": "The Goodwill Excel Center is a unique- tuition-free adult charter high school that awards industry recognized certifications and high school diplomas- not GEDs- to adult learners in the District. The first of its kind in the District- the Excel Center responds to the fact that life commitments- and circumstances can often stop people from continuing their high school education. We do this by offering access to transportation assistance- child care and flexible class schedules.",
           "address": "1776 G Street NW  Suite 101, Washington, District of Columbia 20006",
           "address_longitude": -77.041146,
           "address_latitude": 38.897952,
           "phone_number": "(202) 839-3675",
           "phone_number_alt": "(202) 839-3675",
           "enrollment_contact_name": "Dawn Rhodes",
           "enrollment_contact_email": "dawn.rhodes@goodwillexcelcenter.org",
           "website_url": "http://www.goodwillexcelcenter.org",
           "min_age": 14,
           "max_age": 99,
           "building_open_time": "09:00AM",
           "building_close_time": "05:00PM",
           "program_start_time": "09:00AM",
           "program_end_time": "05:00PM",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": true,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Farragut West",
           "bus": "80/32/34",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": true,
           "req_notes": "0",
           "open_seats": 360,
           "application_deadline": "2020-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "2020-01-08T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2016-03-03T00:00:00",
           "updated_at": "2019-11-25T00:00:00",
           "site_status": "Updated",
           "rec_childcare": true,
           "ward": 2,
           "class_computer_literacy": true,
           "hours_per_week": 32,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 23,
           "program_name": "Living Wages of Washington",
           "program_description": "At Living Wages we: believe in the concept of lifelong learning as a means of enabling people to contribute to and participate personally- socially- economically and collectively in the development of society affirm and support the key role of adult and community education in promoting equality- social inclusion and active citizenship; promote a just wage that ensures an existence worthy of human dignity commit to the development and sustainability of quality adult and community education services in Washington, D.C.",
           "address": "4235 4th Street Southeast, Washington, District of Columbia 20032",
           "address_longitude": -77.00051,
           "address_latitude": 38.826832,
           "phone_number": "(202) 574-3961",
           "phone_number_alt": "",
           "enrollment_contact_name": "",
           "enrollment_contact_email": "",
           "website_url": "",
           "min_age": 18,
           "max_age": 99,
           "building_open_time": "09:00AM",
           "building_close_time": "05:00PM",
           "program_start_time": "",
           "program_end_time": "",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": false,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "None",
           "bus": "A2,A6,A8",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "",
           "accepting_applications": "0",
           "created_at": "2015-09-17T00:00:00",
           "updated_at": "2016-09-30T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 8,
           "class_computer_literacy": false,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "0",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 24,
           "program_name": "Luke C Moore Opportunity Academy",
           "program_description": "Luke C. Moore High School’s mission is to inspire, challenge, and empower our scholars by promoting their academic, social and emotional well-being. We are committed to developing a community of life-long learners by Engaging students by Addressing social and emotional needs through Goal-oriented instruction and developing Life-Long Learners, which Encourages community engagement through purposeful action for Social change.",
           "address": "1001 Monroe St NE, Washington, District of Columbia 20017",
           "address_longitude": -76.992102,
           "address_latitude": 38.93199,
           "phone_number": "(202) 281-3600",
           "phone_number_alt": "(202) 281-3600",
           "enrollment_contact_name": "Debra Ginyard",
           "enrollment_contact_email": "Debra.ginyard@dc.gov",
           "website_url": "http://profiles.dcps.dc.gov/Luke+C.+Moore+High+School",
           "min_age": 16,
           "max_age": 22,
           "building_open_time": "07:00AM",
           "building_close_time": "08:00PM",
           "program_start_time": "09:15AM",
           "program_end_time": "05:30PM",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": true,
           "class_cosmetology": true,
           "class_childcare": true,
           "class_medical": true,
           "class_culinary": false,
           "class_computer": true,
           "class_hvac": true,
           "class_construction": true,
           "rec_online_courses": true,
           "rec_credit_recovery": true,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": true,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": true,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Brookland-CUA",
           "bus": "80, G8, H1, H2, H4, H8, H9",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 10,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "0",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2018-07-06T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": true,
           "ward": 5,
           "class_computer_literacy": true,
           "hours_per_week": 25,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": false,
           "rec_internships": true,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": true,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": true,
           "rec_mental_health_services": true,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": true,
           "rec_housing_emergency_shelter": true,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "1",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "1",
           "Family_Literacy": "1",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "1",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "22"
         },
         {
           "Prog_ID": 26,
           "program_name": "Maya Angelou Young Adult Learning Center",
           "program_description": "The Maya Angelou Young Adult Learning Center (YALC) provides academic and workforce development to older youth/young adults, ages 17-24, who do not have a high school credential, and particularly those transitioning from incarceration.",
           "address": "5600 East Capitol Street Northeast, Washington, District of Columbia 20019",
           "address_longitude": -76.919918,
           "address_latitude": 38.890285,
           "phone_number": "(202) 289-8898 x1709",
           "phone_number_alt": "(202) 289-8898 x1709",
           "enrollment_contact_name": "Kewanna Wade",
           "enrollment_contact_email": "kwade@seeforever.org",
           "website_url": "http://www.seeforever.org/maya-angelou-public-charter-schools/young-adult-learning-center/",
           "min_age": 17,
           "max_age": 25,
           "building_open_time": "08:45AM",
           "building_close_time": "03:30PM",
           "program_start_time": "09:00AM",
           "program_end_time": "03:30PM",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": true,
           "class_construction": true,
           "rec_online_courses": false,
           "rec_credit_recovery": true,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": true,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": true,
           "metro": "Capitol Heights",
           "bus": "96,97",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 85,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2018-09-04T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 7,
           "class_computer_literacy": false,
           "hours_per_week": 20,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": false,
           "rec_stipend": true,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": true,
           "rec_mental_health_services": true,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": true,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "1",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "1",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "1",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "25"
         },
         {
           "Prog_ID": 27,
           "program_name": "Next Step Public Charter School (El Proximo Paso) Day Program",
           "program_description": "The Next Step offers students the support, knowledge and skills they need to succeed academically and professionally. We offer GED preparation in English and Spanish as well as English as a Second Language (ESL) to DC youth between the ages of 16 and 30. We offer a  day program and a night program, which run from September through August, enrolling two times each year. With a bilingual staff (English and Spanish) and wrap-around case management, we provide a safe and nurturing environment that supports students and their families. The Day School program operates between 9 am and 3 pm Monday-Friday.",
           "address": "3047 15th Street Northwest, Washington, District of Columbia 20009",
           "address_longitude": -77.035777,
           "address_latitude": 38.928522,
           "phone_number": "(202) 271-4118",
           "phone_number_alt": "(202) 271-4118",
           "enrollment_contact_name": "Jarolyn Alvarez Dobson ",
           "enrollment_contact_email": "jarolyn@nextsteppcs.org",
           "website_url": "http://nextsteppcs.org/",
           "min_age": 16,
           "max_age": 24,
           "building_open_time": "08:00AM",
           "building_close_time": "05:00PM",
           "program_start_time": "09:00AM",
           "program_end_time": "03:00PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": true,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": true,
           "rec_case_management": true,
           "rec_career_placement": false,
           "rec_postsec_placement": true,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Columbia Heights",
           "bus": "S1, S2, S3, S4, H1, H2, H3, H4, H8, 51, 52, 42, D32, DC98",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": true,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "2017-04-03T00:00:00",
           "program_start_date": "2020-09-08T00:00:00",
           "enrollment_cycles": "Semi-Annual",
           "accepting_applications": "1",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2020-05-28T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": true,
           "ward": 1,
           "class_computer_literacy": true,
           "hours_per_week": 25,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": true,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": true,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": true,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "24"
         },
         {
           "Prog_ID": 28,
           "program_name": "Next Step Public Charter School (El Proximo Paso) Night Program",
           "program_description": "The Next Step offers students the support, knowledge and skills they need to succeed academically and professionally. We offer GED preparation in English and Spanish as well as English for Speakers of Other Languages (ESL) to DC youth between the ages of 18 and 30. We offer a day program and a night program, which run from September through August, enrolling two times each year. With a bilingual staff (English and Spanish) and wrap-around case management, we provide a safe and nurturing environment that supports students and their families. The Night School program operates between 6 pm and 9 pm Monday-Thursday.",
           "address": "3047 15th Street Northwest, Washington, District of Columbia 20009",
           "address_longitude": -77.035777,
           "address_latitude": 38.928522,
           "phone_number": "(202) 957-7090",
           "phone_number_alt": "(202) 957-7090",
           "enrollment_contact_name": "Rafael Ynfante",
           "enrollment_contact_email": "rafael@nextsteppcs.org",
           "website_url": "http://nextsteppcs.org/",
           "min_age": 18,
           "max_age": 30,
           "building_open_time": "01:00PM",
           "building_close_time": "09:00PM",
           "program_start_time": "06:00PM",
           "program_end_time": "09:00PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": true,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": true,
           "rec_case_management": true,
           "rec_career_placement": false,
           "rec_postsec_placement": true,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Columbia Heights",
           "bus": "S1, S2, S3, S4, H1, H2, H3, H4, H8, 51, 52, 42, D32, DC98",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": true,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "2017-04-03T00:00:00",
           "program_start_date": "2020-09-08T00:00:00",
           "enrollment_cycles": "Semi-Annual",
           "accepting_applications": "0",
           "created_at": "2016-04-20T00:00:00",
           "updated_at": "2020-05-28T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": true,
           "ward": 1,
           "class_computer_literacy": false,
           "hours_per_week": 12,
           "rec_night_classes": true,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": true,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": true,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "0",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "30"
         },
         {
           "Prog_ID": 30,
           "program_name": "Roosevelt STAY Opportunity Academy (Comprehensive High School)",
           "program_description": "The mission of Roosevelt STAY Opportunity Academy is to deliver a high-quality academic and career/technical program within a student-centered, alternative environment that will lead to a high school diploma. The primary student population includes in-school day students enrolled in other high schools across the city who need to take additional classes in order to graduate on time, as well as older students returning to school. ",
           "address": "4301 13th Street Northwest, Washington, District of Columbia 20011",
           "address_longitude": -77.027829,
           "address_latitude": 38.943395,
           "phone_number": "(202) 576-8399",
           "phone_number_alt": "(202) 576-8399",
           "enrollment_contact_name": "Maritza Casso",
           "enrollment_contact_email": "maritza.casso@k12.dc.gov",
           "website_url": "www.rooseveltstay.org",
           "min_age": 16,
           "max_age": 24,
           "building_open_time": "08:30AM",
           "building_close_time": "08:30PM",
           "program_start_time": "09:00AM",
           "program_end_time": "07:21PM",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": true,
           "class_barbering": true,
           "class_cosmetology": true,
           "class_childcare": true,
           "class_medical": false,
           "class_culinary": true,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": true,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "1",
           "credential_hs_diploma": true,
           "credential_servsafe": true,
           "credential_ic3": false,
           "metro": "Georgia Ave-Petworth",
           "bus": "52,53,54,70",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": true,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "2019-08-26T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2019-11-05T00:00:00",
           "site_status": "Updated",
           "rec_childcare": true,
           "ward": 4,
           "class_computer_literacy": false,
           "hours_per_week": 30,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "1",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "24"
         },
         {
           "Prog_ID": 31,
           "program_name": "Roosevelt STAY Opportunity Academy (Evening Program)",
           "program_description": "The mission of Roosevelt STAY Senior High School is to deliver a high-quality academic and career/technical program within a student-centered, alternative environment that will lead to a high school diploma. The primary student population includes in-school day students enrolled in other high schools across the city who need to take additional classes in order to graduate on time, as well as older students returning to school. ",
           "address": "4301 13th Street Northwest, Washington, District of Columbia 20011",
           "address_longitude": -77.027829,
           "address_latitude": 38.943395,
           "phone_number": "(202) 576-8399",
           "phone_number_alt": "(202) 576-8399",
           "enrollment_contact_name": "Maritza Casso",
           "enrollment_contact_email": "maritza.casso@dc.gov",
           "website_url": "rooseveltstay.org",
           "min_age": 25,
           "max_age": 0,
           "building_open_time": "08:30AM",
           "building_close_time": "09:00PM",
           "program_start_time": "04:20PM",
           "program_end_time": "07:20AM",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": true,
           "class_barbering": true,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": true,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "1",
           "credential_hs_diploma": false,
           "credential_servsafe": true,
           "credential_ic3": false,
           "metro": "Georgia Ave-Petworth",
           "bus": "52,53,54",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 50,
           "application_deadline": "2019-01-28T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "2019-01-28T00:00:00",
           "enrollment_cycles": "Semi-Annual",
           "accepting_applications": "0",
           "created_at": "2018-04-06T00:00:00",
           "updated_at": "2018-11-07T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 4,
           "class_computer_literacy": false,
           "hours_per_week": 30,
           "rec_night_classes": true,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 32,
           "program_name": "Sasha Bruce YouthWork ",
           "program_description": "The mission of Sasha Bruce Youthwork is to improve the lives of runaway, homeless, abused, and neglected at-risk youth and their families in the Washington area. We achieve this by providing shelter, counseling, life skills training and positive youth development activities to approximately 1,500 youth and 5,000 family members each year.",
           "address": "741 8th street SE , Washington, District of Columbia 20003",
           "address_longitude": -76.995353,
           "address_latitude": 38.8798,
           "phone_number": "(202) 675-9340",
           "phone_number_alt": "(202) 675-9340 x212",
           "enrollment_contact_name": "Pam Lieber",
           "enrollment_contact_email": "Plieber ",
           "website_url": "",
           "min_age": 13,
           "max_age": 24,
           "building_open_time": "09:00AM",
           "building_close_time": "05:00PM",
           "program_start_time": "09:00AM",
           "program_end_time": "05:00PM",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Eastern Market",
           "bus": "90, 92",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "",
           "accepting_applications": "0",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2016-11-14T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": true,
           "ward": 0,
           "class_computer_literacy": false,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": true,
           "rec_housing_emergency_shelter": true,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "1",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "24"
         },
         {
           "Prog_ID": 34,
           "program_name": "Southeast Ministries ",
           "program_description": "Daily Adult Basic Education (ABE) classes are held in a classroom setting where all five GED exam subjects are taught, including math, reading, science, social studies, and writing. Additionally, small group tutoring is made available to learners throughout the course’s entirety.",
           "address": "3111 Martin Luther King Junior Avenue Southeast, Washington, District of Columbia 20032",
           "address_longitude": -76.999762,
           "address_latitude": 38.843606,
           "phone_number": "(202) 562-2636",
           "phone_number_alt": "(202) 562-2636 x301",
           "enrollment_contact_name": "Diane Harris",
           "enrollment_contact_email": "dharris@southeastministrydc.org",
           "website_url": "http://southeastministrydc.org",
           "min_age": 18,
           "max_age": 99,
           "building_open_time": "08:30AM",
           "building_close_time": "04:30PM",
           "program_start_time": "08:30AM",
           "program_end_time": "04:30PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": true,
           "rec_postsec_placement": true,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Congress Heights",
           "bus": "A2, A4, A8, W1",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "0",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2016-09-30T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 8,
           "class_computer_literacy": false,
           "hours_per_week": 12,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 36,
           "program_name": "Youthbuild PCS ",
           "program_description": "YouthBuild Public Charter School (YBPCS) is an alternative high school for young people seeking to transform their lives by re-engaging in their education in a non-traditional school environment. YBPCS prepares students for post-secondary education and the workplace by offering, in English and Spanish, academic, vocational and workforce development programs. YBPCS believes that service to the community is an essential part of the transformative process. Therefore, students serve their community through volunteer projects and by creating housing for low income residents in the Washington, DC metropolitan area.",
           "address": "3220 16th St NW, Washington, District of Columbia 20010",
           "address_longitude": -77.032738,
           "address_latitude": 38.927789,
           "phone_number": "(202) 319-0141",
           "phone_number_alt": "(202) 319-0141",
           "enrollment_contact_name": "Komal Bansal",
           "enrollment_contact_email": "Komal.Bansal@youthbuildpcs.org",
           "website_url": "http://www.youthbuildpcs.org",
           "min_age": 16,
           "max_age": 24,
           "building_open_time": "09:00AM",
           "building_close_time": "03:00PM",
           "program_start_time": "09:00AM",
           "program_end_time": "03:00PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": true,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": true,
           "rec_online_courses": true,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": true,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Columbia Heights",
           "bus": "52, 53, 54, D32, H1, H2, H3, H4, H8, Circulator (Woodley Park - McPherson Square)",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 30,
           "application_deadline": "2021-04-23T00:00:00",
           "application_open_date": "2017-01-02T00:00:00",
           "program_start_date": "2021-04-26T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2021-04-05T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 1,
           "class_computer_literacy": true,
           "hours_per_week": 30,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": true,
           "rec_internships": true,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": true,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": true,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "24"
         },
         {
           "Prog_ID": 37,
           "program_name": "YWCA National Capital Area Career Education and Training Center (CETC)",
           "program_description": "The Career Education & Training Center (CETC) provides women and men with essential education, life skills, and workforce readiness. Earn a nationally-recognized certification in Hospitality, Administrative Support, or Healthcare. CETC also offers assistance in obtaining a GED.",
           "address": "2303 14th Street Northwest, Washington, District of Columbia 20009",
           "address_longitude": -77.031444,
           "address_latitude": 38.920614,
           "phone_number": "(202) 626-0700",
           "phone_number_alt": "(202) 626-0700 x729",
           "enrollment_contact_name": "Angela Hughes",
           "enrollment_contact_email": "ahughes@ywcanca.org",
           "website_url": "ywcanca.org",
           "min_age": 18,
           "max_age": 0,
           "building_open_time": "",
           "building_close_time": "",
           "program_start_time": "09:30AM",
           "program_end_time": "08:00PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": true,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": true,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": true,
           "credential_ic3": true,
           "metro": "U Street/African-Amer Civil War Memorial/Cardozo",
           "bus": "52, 53, 54",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Other",
           "accepting_applications": "1",
           "created_at": "2016-02-25T00:00:00",
           "updated_at": "2020-10-15T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 1,
           "class_computer_literacy": true,
           "hours_per_week": 24,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": false,
           "rec_internships": true,
           "math_CASAS_score": "204",
           "reading_CASAS_score": "217",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "1",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 14,
           "program_name": "Catholic Charities Green Construction Program (Spanish)\r\n",
           "program_description": "GREEN CONSTRUCTION PROGRAM This is a construction program for students interested in pursuing a career pathway in the construction field. When completed- students will graduate with four certifications: NCCER Core Curriculum- Flagger- OSHA-10 Safety- and CPR-AED/First Aid. The course will prepare students with foundational knowledge of construction in hand tools- construction drawings- rigging- communication skills- material handling- and employability skills. The program is intended to serve individuals looking to pursue an entry level construction position- students who want to further their education- or those in the construction field seeking to increase their knowledge.\n\nLATINOS IN FINANCE: INVESTING IN BILINGUAL BANKING & FINANCE PROFESSIONALS PROJECT- BANK TELLER PROGRAM\nThe Bank Teller Program prepares bilingual students for an entry level position in the financial industry. The curriculum will address customer service- financial principles- and workforce preparedness. In addition- students participate in a comprehensive workforce program that focuses on training- placement- and retention in addition to highly focused case management. Students will frequently interact with professionals in the banking sector who assist in the instruction of the Bank Telling Program.\r\n",
           "address": "1618 Monroe Street NW, Washington, District of Columbia 20010",
           "address_longitude": -77.0373987,
           "address_latitude": 38.932731,
           "phone_number": "",
           "phone_number_alt": "(202) 939-2400 x932",
           "enrollment_contact_name": "Celia Sterling",
           "enrollment_contact_email": "celia.sterling@cc-dc.org",
           "website_url": "www.catholiccharitiesdc.org",
           "min_age": 18,
           "max_age": 0,
           "building_open_time": "08:30AM",
           "building_close_time": "04:30PM",
           "program_start_time": "",
           "program_end_time": "",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": true,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": true,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Columbia Heights",
           "bus": "",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 20,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Other",
           "accepting_applications": "1",
           "created_at": "2018-08-09T00:00:00",
           "updated_at": "2018-11-07T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 1,
           "class_computer_literacy": false,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "",
           "reading_CASAS_score": "",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "0",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 21,
           "program_name": "Kingsman Academy R.I.S.E.\r\n",
           "program_description": "Kingsman Academy's R.I.S.E. Program offers a non-traditional learning environment for scholars most at risk of not graduating from school. The program provides an alternative learning environment for scholars to promote the success of each individual. By reinforcing the program values and fostering healthy scholar growth and development- R.I.S.E. helps more students graduate and become successful after graduation.\r\nR.I.S.E. serves scholars at-risk of not graduating from high school--including those who may age out of the public school system- lack internal motivation to complete traditional coursework- are homeless- or are parenting. R.I.S.E. scholars have opportunity to learn in an alternative environment to meet the academic requirements necessary to earn a high school diploma while gaining the skills and values necessary to successfully transition from a high school scholar to self-reliant member of the workforce.",
           "address": "1375 E Street Northeast, Washington, District of Columbia 20002",
           "address_longitude": -76.986219,
           "address_latitude": 38.895754,
           "phone_number": "(202) 547-1028",
           "phone_number_alt": "(202) 547-1028 x222",
           "enrollment_contact_name": "Melanie Paris",
           "enrollment_contact_email": "enroll@kingsmanacademy.org",
           "website_url": "kingsmanacademy.org",
           "min_age": 16,
           "max_age": 22,
           "building_open_time": "03:30PM",
           "building_close_time": "07:30PM",
           "program_start_time": "",
           "program_end_time": "",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": true,
           "rec_credit_recovery": true,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": false,
           "rec_postsec_placement": false,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": true,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Stadium-Armory",
           "bus": "B2",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 20,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "0",
           "created_at": "2017-06-22T00:00:00",
           "updated_at": "2018-04-09T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 6,
           "class_computer_literacy": false,
           "hours_per_week": 28,
           "rec_night_classes": true,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": false,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "1",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "0",
           "Jobsearch_Placement": "0",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "22"
         },
         {
           "Prog_ID": 22,
           "program_name": "LAYC Career Academy",
           "program_description": "The LAYC Career Academy is an innovative model that provides youth ages 16-24 with college credits / AP-style classes; a rigorous and flexible GED, college preparatory curriculum; and career preparation in the healthcare and information technology fields.\n\nLAYC Career Academy is a school for youth who want to: \n\n• Become a Medical Assistant or enter the medical field or;\n• Become an A+ Certified Computer Hardware Technician or;\n• Succeed on the GED, and/ or\n• Earn free college credit\r\n",
           "address": "3224 16th Street NW, Washington, District of Columbia 20010",
           "address_longitude": -77.035777,
           "address_latitude": 38.928522,
           "phone_number": "(202) 319-2228",
           "phone_number_alt": "(202) 319-2228",
           "enrollment_contact_name": "Ashley McQueen",
           "enrollment_contact_email": "a.mcqueen@laycca.org",
           "website_url": "www.laycca.org",
           "min_age": 16,
           "max_age": 24,
           "building_open_time": "08:30AM",
           "building_close_time": "04:30PM",
           "program_start_time": "08:55AM",
           "program_end_time": "03:00PM",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": true,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": true,
           "class_culinary": false,
           "class_computer": true,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": true,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": true,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": true,
           "metro": "Columbia Heights",
           "bus": "S1, S2, S3, S4, H1, H2, H3, H4, H8, 51, 52, 42",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": true,
           "req_notes": "0",
           "open_seats": 60,
           "application_deadline": "2019-12-03T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "2020-01-06T00:00:00",
           "enrollment_cycles": "Semi-Annual",
           "accepting_applications": "1",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2019-11-07T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 1,
           "class_computer_literacy": true,
           "hours_per_week": 30,
           "rec_night_classes": false,
           "rec_project_based_learning": true,
           "rec_dual_enrollment": true,
           "rec_internships": true,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": true,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": true,
           "rec_mental_health_services": true,
           "rec_food_bank": true,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": true,
           "rec_independent_living": true,
           "rec_housing_emergency_shelter": true,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "1",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "1",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "24"
         },
         {
           "Prog_ID": 25,
           "program_name": "Maya Angelou Evans Campus",
           "program_description": "Our focus is on making students ready for the world, helping them to\nbe career prepared, and assisting them in becoming lifelong learners and contributors to society. DC residents grades 9-11. \nA comprehensive approach to education that focuses on academic\nachievement, social and emotional support and career/college preparation for life after Maya.",
           "address": "5600 East Capitol Street Northeast, Washington, District of Columbia 20019",
           "address_longitude": -76.919918,
           "address_latitude": 38.890285,
           "phone_number": "(202) 379-4335",
           "phone_number_alt": "(202) 379-4335 x1115",
           "enrollment_contact_name": "Denean Stevens",
           "enrollment_contact_email": "Dstevens@seeforever.org",
           "website_url": "http://www.seeforever.org/maya-angelou-public-charter-schools/high-school/",
           "min_age": 14,
           "max_age": 21,
           "building_open_time": "08:00AM",
           "building_close_time": "04:30PM",
           "program_start_time": "08:45AM",
           "program_end_time": "04:05PM",
           "class_hs_diploma": false,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": true,
           "class_computer": true,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": true,
           "rec_spanish_instruction": true,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": true,
           "rec_meals": true,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": true,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Capitol Heights",
           "bus": "96,97",
           "req_dc_resident": true,
           "req_drug_test": false,
           "req_vaccination": true,
           "req_notes": "0",
           "open_seats": 100,
           "application_deadline": "2019-03-04T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "2019-03-04T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2018-11-07T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 7,
           "class_computer_literacy": false,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "NA",
           "reading_CASAS_score": "NA",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": true,
           "rec_mental_health_services": true,
           "rec_food_bank": true,
           "rec_residential_program": true,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": true,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "1",
           "CollegeReady_AccuP": "1",
           "HealthSA_Counseling": "1",
           "Family_Literacy": "0",
           "Food_Services": "1",
           "Childcare": "0",
           "Street_Outreach": "1",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "1",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "1",
           "max_age_x": "21"
         },
         {
           "Prog_ID": 29,
           "program_name": "Opportunities Industrialization Center of DC (OIC/DC)\r\n",
           "program_description": "OIC/DC is a licensed training institution offering day and evening class for following programs: *Home Health Aide\n*A+ Certification\n*BOSS (Business Office Support Specialist w/MOS)\n*Family To Family ",
           "address": "3016 Martin Luther King Junior Avenue Southeast, Washington, District of Columbia 20032",
           "address_longitude": -76.99761,
           "address_latitude": 38.844416,
           "phone_number": "(202) 373-0330",
           "phone_number_alt": "(202) 373-0330",
           "enrollment_contact_name": "Octaviouse Greene",
           "enrollment_contact_email": "Ogreene@oicdc.org",
           "website_url": "www.oicdc.org",
           "min_age": 18,
           "max_age": 0,
           "building_open_time": "09:00AM",
           "building_close_time": "09:00PM",
           "program_start_time": "",
           "program_end_time": "",
           "class_hs_diploma": true,
           "class_job_training": true,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": true,
           "class_culinary": false,
           "class_computer": true,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "1",
           "credential_nedp": "0",
           "credential_hs_diploma": true,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Congress Heights",
           "bus": "A2,A4, A6, A8, W4",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "",
           "accepting_applications": "1",
           "created_at": "2014-11-24T00:00:00",
           "updated_at": "2019-10-29T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 8,
           "class_computer_literacy": true,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "7th",
           "reading_CASAS_score": "8th",
           "rec_parenting_support": true,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": true,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 33,
           "program_name": "So Others Might Eat (SOME) Center for Employment Training \r\n",
           "program_description": "SOME CET provides training to prepare individuals for careers as Medical Administrative Assistants and Building Maintenance Service Technicians. Students earn occupational credentials and certifications.\n\nMedical Administrative Assistant\nStudents learn about allied health professions- health care communications- medical records and billing- computer training- reception and clerical skills.\n\nStudents can earn the Medical Administrative Assistant Certification (CMAA)\n\nBuilding Maintenance Service Technician\n Students learn carpentry- electrical engineering- plumbing- HVAC-  and safety standards.\n\nStudents can earn the EPA Section 608 Technician Certification\n\nElectronic Health Records\n Students learn to manage Electronic Health Records systems and to perform tasks such as billings- record verification and reporting.\n\nStudents can earn the Certified Electronic Health Record Specialist certification\r\n",
           "address": "4430 Benning Road Northeast, Washington, District of Columbia 20019",
           "address_longitude": -76.938098,
           "address_latitude": 38.891053,
           "phone_number": "(202) 797-8806",
           "phone_number_alt": "(202) 701-1229",
           "enrollment_contact_name": "Thomas Worthington",
           "enrollment_contact_email": "tworthington@some.org",
           "website_url": "https://some.org/services/some-center-for-employment-training/",
           "min_age": 18,
           "max_age": 0,
           "building_open_time": "08:00AM",
           "building_close_time": "",
           "program_start_time": "08:00AM",
           "program_end_time": "03:30PM",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": false,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": true,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": true,
           "class_construction": true,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": true,
           "rec_career_placement": true,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Benning Road",
           "bus": "",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Bi-Weekly",
           "accepting_applications": "1",
           "created_at": "2018-07-06T00:00:00",
           "updated_at": "2020-07-15T00:00:00",
           "site_status": "Updated",
           "rec_childcare": false,
           "ward": 6,
           "class_computer_literacy": false,
           "hours_per_week": 30,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": true,
           "math_CASAS_score": "4th",
           "reading_CASAS_score": "224",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": true,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "1",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         },
         {
           "Prog_ID": 35,
           "program_name": "Washington English Center ESOL Programs\r\n",
           "program_description": "Mon-Thurs 9:00-8:00pm; Sat -Sun 9:00-4pm; Closed Fridays\n\nSchedule for ESOL\n \nEarly registration (before the first day of class)/Late registration  (on and after the first day of class)\n \nDaytime classes $155/ $165\nMonday to Thursday /10 am-1 pm/ September 17 – December 6\n \nEvening classes $135/ $145\nMonday to Thursday /7 pm-9 pm/ September 17 – December 6\n \nWeekend classes $135/ $145; \nSaturday & Sunday /2 pm-5 pm/ September 22 – December 9 \n \nAdditional Classes to begin \n \nComputer Literacy (in English) $45\nTuesday & Thursday/ 6pm-7pm/ September 25 – December 6\n \nCitizenship Preparation (in English) $75\nMonday – Thursday/ 6pm-7pm/ September 24 – December 6\n \n  \nWashington English Center also offers free a Employment Services Program to all students. Some of the ways we can assist you include:\nExploring career certification and training options\nWriting a resume and cover letter\nSearching for jobs on the internet\nSubmitting job applications \nHelping you prepare for a job interview or a job fair\nProviding employment counseling\r\n",
           "address": "2200 California Street Northwest, Washington, District of Columbia 20008",
           "address_longitude": -77.049025,
           "address_latitude": 38.915645,
           "phone_number": "(202) 387-2222",
           "phone_number_alt": "(202) 387-2222",
           "enrollment_contact_name": "Claudia Bellos o & Clodoaldo Lopez",
           "enrollment_contact_email": "info@washingtonenglish.org",
           "website_url": "http://www.washingtonenglish.org",
           "min_age": 17,
           "max_age": 0,
           "building_open_time": "09:00AM",
           "building_close_time": "08:00PM",
           "program_start_time": "",
           "program_end_time": "",
           "class_hs_diploma": true,
           "class_job_training": false,
           "class_english_language": true,
           "class_barbering": false,
           "class_cosmetology": false,
           "class_childcare": false,
           "class_medical": false,
           "class_culinary": false,
           "class_computer": false,
           "class_hvac": false,
           "class_construction": false,
           "rec_online_courses": false,
           "rec_credit_recovery": false,
           "rec_spanish_instruction": false,
           "rec_case_management": false,
           "rec_career_placement": false,
           "rec_postsec_placement": false,
           "rec_meals": false,
           "rec_program_notes": "",
           "credential_ged": "0",
           "credential_nedp": "0",
           "credential_hs_diploma": false,
           "credential_servsafe": false,
           "credential_ic3": false,
           "metro": "Dupont Circle",
           "bus": " 42,43 H1, L1, L2",
           "req_dc_resident": false,
           "req_drug_test": false,
           "req_vaccination": false,
           "req_notes": "0",
           "open_seats": 0,
           "application_deadline": "0001-01-01T00:00:00",
           "application_open_date": "0001-01-01T00:00:00",
           "program_start_date": "0001-01-01T00:00:00",
           "enrollment_cycles": "Rolling",
           "accepting_applications": "1",
           "created_at": "2014-10-22T00:00:00",
           "updated_at": "2018-11-07T00:00:00",
           "site_status": "Not Updated",
           "rec_childcare": false,
           "ward": 3,
           "class_computer_literacy": true,
           "hours_per_week": 0,
           "rec_night_classes": false,
           "rec_project_based_learning": false,
           "rec_dual_enrollment": false,
           "rec_internships": false,
           "math_CASAS_score": "",
           "reading_CASAS_score": "",
           "rec_parenting_support": false,
           "rec_childcare_subsidy": false,
           "rec_stipend": false,
           "rec_onsite_accomodations": false,
           "rec_substance_counseling": false,
           "rec_mental_health_services": false,
           "rec_food_bank": false,
           "rec_residential_program": false,
           "rec_job_placement": true,
           "class_workforce_readiness": false,
           "rec_independent_living": false,
           "rec_housing_emergency_shelter": false,
           "rec_alumni_support": false,
           "rec_automotive_technology": false,
           "SupplCred_RO": "0",
           "CollegeReady_AccuP": "0",
           "HealthSA_Counseling": "0",
           "Family_Literacy": "0",
           "Food_Services": "0",
           "Childcare": "0",
           "Street_Outreach": "0",
           "Independent_Living": "0",
           "Referrals_Provided": "1",
           "Jobsearch_Placement": "1",
           "Postsec_Placement": "0",
           "Transportation_Subsidy": "0",
           "Stipend": "0",
           "HS_Credentials": "0",
           "max_age_x": "no maximum age limit"
         }
       ];
    }
}
